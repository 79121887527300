import classNames from 'classnames'
import { Bars3Icon, ChevronLeftIcon } from '@heroicons/react/24/outline'
import Logo from '../logo/Logo'
import ProfileDropdownMenu from './ProfileDropdownMenu'
import SidebarNavigationDesktop from './SidebarNavigationDesktop'
import WorkspaceIdentity from './WorkspaceIdentity'

/* eslint-disable max-lines-per-function */
export default function SidebarMenu({
  user,
  permissions,
  userNavigation,
  workspaceButtonReference,
  workspace,
  showSidebar,
  isOpen,
  handleIntercomClick,
  sidebarCollapsed,
  setSidebarCollapsed,
  setSidebarOpen,
  setWorkspacePanelOpen
}) {
  return (
    <>
      <div className="relative flex items-center flex-shrink-0 px-6">
        <Logo className={classNames('transition-all', sidebarCollapsed ? 'opacity-0' : 'opacity-100')} />
        <button
          onClick={() => setSidebarCollapsed(!sidebarCollapsed)}
          className="absolute right-0 p-4 cursor-pointer max-lg:hidden"
          data-testid="sidebar-collapser"
        >
          {sidebarCollapsed ? (
            <Bars3Icon className="absolute top-0 w-8 h-8 text-background-500 right-6" />
          ) : (
            <ChevronLeftIcon className="absolute top-0 w-5 h-5 text-background-500 right-4" />
          )}
        </button>
        <button
          onClick={() => setSidebarOpen(false)}
          className="absolute right-0 p-4 cursor-pointer lg:hidden"
          data-testid="sidebar-collapser-mobile"
        >
          <ChevronLeftIcon className="absolute top-0 w-5 h-5 text-background-500 right-4" />
        </button>
      </div>
      <div className="flex flex-col flex-1 h-0 pt-2 mt-6 overflow-hidden">
        <ProfileDropdownMenu
          userName={`${user?.firstName} ${user?.lastName}`}
          userOrg={user?.defaultOrgName}
          sidebarCollapsed={sidebarCollapsed}
          userNavigation={userNavigation}
          userAvatar={user?.photoURL}
          userEmail={user?.email}
        />
        {user?.workspaces && !!user?.workspaces?.length && (
          <div className="mt-[60px] px-3">
            <WorkspaceIdentity
              buttonRef={workspaceButtonReference}
              title={workspace?.getWorkspaceName?.(user.defaultWorkspaceId)}
              subtitle="Workspace"
              sidebarCollapsed={sidebarCollapsed}
              useIndigoBackground
              isWorkspaceSwitchable={user?.workspaces?.length > 1}
              setWorkspacePanelOpen={setWorkspacePanelOpen}
              onTitleFinishChange={() => workspace?.syncWorkspaceName?.(user.defaultWorkspaceId)}
              onTitleChange={(value) => workspace?.changeWorkspaceName?.(user.defaultWorkspaceId, value)}
              showSidebar={showSidebar}
            />
          </div>
        )}
        <SidebarNavigationDesktop
          user={user}
          permissions={permissions}
          sidebarCollapsed={sidebarCollapsed}
          onClick={() => setSidebarOpen(false)}
          isOpen={isOpen}
          handleIntercomClick={handleIntercomClick}
        />
      </div>
    </>
  )
}
