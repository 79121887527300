import { useContext, createContext } from 'react'
import useAuthProvider from '../hooks/useAuthProvider'

export const authContext = createContext()
authContext.displayName = 'AuthContext'

export default function AuthProvider({ children }) {
  const auth = useAuthProvider()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export const useAuth = () => useContext(authContext)

// TODO: Will remove this functions after Full Migration to AWS
// Handle Firebase email link for reverting to original email after an email change
export const handleRecoverEmail = async (code) => {}

// Handle Firebase email link for verifying email
export const handleVerifyEmail = (code) => {}
