import { createContext } from 'react'
import usePersona from '../hooks/dataFetching/usePersona'

export const PersonaContext = createContext()

const PersonaProvider = ({ children }) => {
  const persona = usePersona()
  return <PersonaContext.Provider value={persona}>{children}</PersonaContext.Provider>
}

export default PersonaProvider
