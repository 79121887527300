import { LinkIcon } from '@heroicons/react/20/solid'
import Button from '../ui/button/Button'

export default function CopyLinkButton() {
  return (
    <Button customColor="bg-gray-100 text-gray-500 hover:bg-gray-200" className="flex items-center">
      <LinkIcon className="w-5 h-5 mr-2" />
      Copy Link
    </Button>
  )
}
