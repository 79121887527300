import { ChevronDownIcon, QuestionMarkCircleIcon } from '@heroicons/react/20/solid'
import { useContext, useEffect } from 'react'
import Button from '../ui/button/Button'
import MultiSelectInput from '../ui/form/MultiSelectInput'
import Modal from '../ui/modal/Modal'
import ComboBoxButton from '../ui/form/ComboBoxButton'
import TooltipComposer from '../ui/tooltip/TooltipComposer'
import PageLoader from '../helpers/PageLoader'
import useChangeRole from '../../hooks/useChangeRole'
import { useAuth } from '../../context/AuthProvider'
import Alert from '../ui/alert/Alert'
import { RoleTypes } from '../../utils/constants'
import useInviteUser from '../../hooks/useInviteUser'
import { WorkspaceContext } from '../../context/WorkspaceProvider'
import MemberItem from './InviteMemberItem'
import CopyLinkButton from './CopyLinkButton'
import VisibilitySettingsDropdown from './VisibilitySettingsDropdown'

export default function InviteMemberModal({
  open,
  onClose,
  showGeneralAccessSection,
  showCopyLinkSection,
  setUserToRemove,
  getOneJourney,
  isLoadingMemberList,
  addMembers,
  changeRole,
  members
}) {
  const auth = useAuth()
  const workspaceId = auth.user.defaultWorkspaceId

  const workspace = useContext(WorkspaceContext)
  const workspaceName = workspace?.getWorkspaceName?.(auth.user.defaultWorkspaceId)

  useEffect(() => {
    if (!open) {
      return
    }
    getOneJourney(workspaceId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const {
    formValue,
    setFormValue,
    onInviteUser,
    inviteType,
    setInviteType,
    isLoading: isInvitingUser,
    errorMessage: inviteUserErrorMessage
  } = useInviteUser({
    onInviteUserSuccess: addMembers
  })

  const {
    changeUserRoleInWorkspace,
    isLoading: isLoadingChangeUserRole,
    errorMessage: changeUserRoleErrorMessage,
    resetErrorMessage
  } = useChangeRole()

  const handleInviteTypeChange = (value) => {
    setInviteType(value.role)
  }

  const handleRemoveUserFromWorkspace = (userId, name) => {
    setUserToRemove(userId, name)
  }

  const handleMemberTypeChange = async ({ userId, value, previousRole, name }) => {
    if (value?.id === 'remove') {
      await handleRemoveUserFromWorkspace(userId, name)
      return
    }
    changeRole(userId, value?.role)
    const result = await changeUserRoleInWorkspace({
      workspaceId,
      userId,
      role: value?.role
    })
    if (!result) {
      changeRole(userId, previousRole)
    }
  }

  return (
    <Modal open={open} onClose={onClose} disableBackdropClick>
      <span className="text-lg font-semibold text-gray-800">Invite to {workspaceName}</span>
      <Modal.Body>
        {(changeUserRoleErrorMessage || inviteUserErrorMessage) && (
          <Alert
            variant="error"
            message={changeUserRoleErrorMessage || inviteUserErrorMessage}
            dismissable
            onClose={resetErrorMessage}
            className="mb-4"
          />
        )}
        <div className="flex space-x-4">
          <div className="flex-grow">
            <MultiSelectInput
              onChange={setFormValue}
              value={formValue}
              placeholder="Type emails separated by a comma"
              customTag="flex items-center font-medium text-sm px-2.5 py-1.5 text-gray-800 border rounded-md border-gray-300"
              actionComponent={
                <ComboBoxButton
                  options={RoleTypes}
                  onChange={handleInviteTypeChange}
                  searchable={false}
                  placement="left-end"
                  optionClassName="!p-0"
                  className="!font-normal w-[100px] text-gray-800"
                  customColor="bg-white shadow-none"
                >
                  {inviteType}
                  <ChevronDownIcon className="inline-block w-5 h-5 text-dark-navy-500" />
                </ComboBoxButton>
              }
            />
          </div>
          <Button
            color="dark"
            customSize="px-5 rounded-md mt-1"
            onClick={() => onInviteUser(workspaceId)}
            disabled={isInvitingUser || formValue?.length === 0}
            data-testid="invite-user-button"
          >
            {isInvitingUser ? 'Inviting...' : 'Invite'}
          </Button>
        </div>
        {showGeneralAccessSection && (
          <div className="my-8">
            <span className="inline-block mb-4 font-medium">
              General Access <QuestionMarkCircleIcon className="inline-block w-5 h-5 ml-2 text-gray-400" />
            </span>
            <VisibilitySettingsDropdown orgName="Triniti" />
          </div>
        )}
        <div className="my-8">
          <span className="inline-block mb-4 text-sm font-medium text-gray-800">People with access</span>
          <div className="flex flex-col space-y-4 overflow-y-auto max-h-64">
            {isLoadingMemberList && <PageLoader />}
            {members?.map((data) => (
              <MemberItem
                key={data.email}
                email={data.email}
                picture={data.picture}
                isPending={data.isPending}
                isLoading={isLoadingChangeUserRole}
                roleName={data.role}
                handleMemberTypeChange={(value) =>
                  handleMemberTypeChange({
                    userId: data.userId,
                    value,
                    previousRole: data.role,
                    name: data.isPending ? data.email : data.name
                  })
                }
                name={data.name}
              />
            ))}
          </div>
        </div>
      </Modal.Body>
      {showCopyLinkSection && (
        <Modal.Footer>
          <CopyLinkButton />
        </Modal.Footer>
      )}
      <TooltipComposer selector=".radio-tooltip" delay={100} offset={7} place="bottom" zIndex={10_000} />
    </Modal>
  )
}
