import { createContext } from 'react'
import useOpportunity from '../hooks/dataFetching/useOpportunity'

export const OpportunityContext = createContext()

const OpportunityProvider = ({ children }) => {
  const opportunity = useOpportunity()
  return <OpportunityContext.Provider value={opportunity}>{children}</OpportunityContext.Provider>
}

export default OpportunityProvider
