import { useEffect } from 'react'

const useInitialFetch = (asyncFunction, onError) => {
  const executeAsyncFunction = async () => {
    try {
      await asyncFunction?.()
    } catch (error) {
      onError?.(error)
    }
  }

  useEffect(() => {
    executeAsyncFunction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useInitialFetch
