import { useRef, useState } from 'react'
import { RoleTypes } from '../utils/constants'
import useWorkspace from './dataFetching/useWorkspace'

const useInviteUser = ({ onInviteUserSuccess }) => {
  const [formValue, changeFormValue] = useState([])
  const formValueReference = useRef([])

  const [inviteType, changeInviteType] = useState('viewer')
  const inviteTypeReference = useRef('viewer')

  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const { inviteToWorkspace } = useWorkspace()

  const setFormValue = (value) => {
    formValueReference.current = value
    changeFormValue(value)
  }

  const setInviteType = (value) => {
    inviteTypeReference.current = value
    changeInviteType(value)
  }

  const onInviteUser = async (workspaceId) => {
    try {
      setErrorMessage(null)
      setIsLoading(true)
      const emails = formValueReference?.current || []
      const role = inviteTypeReference.current
      const result = await inviteToWorkspace({ workspaceId, emails, role })
      const invitedUsers = result?.filter((res) => !res.error)
      onInviteUserSuccess?.(invitedUsers, role)
      setFormValue([])
      setInviteType('viewer')
    } catch (error) {
      setErrorMessage(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  return {
    formValue,
    setFormValue,
    onInviteUser,
    inviteType: RoleTypes?.find((role) => role.role === inviteType)?.name,
    setInviteType,
    isLoading,
    errorMessage
  }
}

export default useInviteUser
