const textEncoder = new TextEncoder()

const generateSHA256Hash = async (text) => {
  const buffer = textEncoder.encode(text)
  const hashBuffer = await crypto.subtle.digest('SHA-256', buffer)
  const hashArray = [...new Uint8Array(hashBuffer)]
  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
}

export default generateSHA256Hash
