import { useRef, useState } from 'react'
import sleep from '../utils/sleep'

const useAlert = () => {
  const [alert, setAlert] = useState(null)
  const alertReference = useRef(null)

  const openAlert = async ({ type, title, message }) => {
    if (alertReference.current) {
      closeAlert()
      await sleep(300)
    }
    setAlert({ type, title, message })
    alertReference.current = { type, title, message }
  }

  const closeAlert = () => {
    setAlert(null)
    alertReference.current = null
  }

  return {
    type: alert?.type,
    title: alert?.title,
    message: alert?.message,
    openAlert,
    closeAlert
  }
}

export default useAlert
