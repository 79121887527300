import { Fragment, useContext, useEffect, useRef } from 'react'
import { Transition } from '@headlessui/react'
import { ChevronLeftIcon, PlusIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'

import Button from '../ui/button/Button'
import useInitialFetch from '../../hooks/useInitialFetch'
import useOnClickOutside from '../../hooks/useClickOutside'
import { useAuth } from '../../context/AuthProvider'
import { pluralize } from '../../utils/text/pluralize'
import WorkspaceModal from '../workspace/WorkspaceModal'
import { WorkspaceContext } from '../../context/WorkspaceProvider'
import DataMapper from '../../utils/dataMapper'
import useWorkspaceSidebar from '../../pages/workspaces/useWorkspaceSidebar'
import WorkspaceIdentity from './WorkspaceIdentity'

export default function WorkspaceSidebar({ isOpen, onClose, buttonRef, hideOnFullScreen, sidebarCollapsed, user }) {
  const { refreshToken, setActiveWorkspaceIndex, getUserRefData, permissions } = useAuth()
  const { getAllWorkspace, result: workspaces = [] } = useContext(WorkspaceContext)

  const isAdmin = !!permissions?.workspaces?.manage

  const {
    isWorkspaceModalVisible,
    activeWorkspace,
    onWorkspaceModalShow,
    onWorkspaceModalClose,
    handleWorkspaceSelect,
    refreshData,
    fetchingData
  } = useWorkspaceSidebar({
    onClose,
    setActiveWorkspaceIndex,
    getAllWorkspace,
    refreshToken,
    getUserRefData
  })

  const wrapperReference = useRef()

  useInitialFetch(() => {
    if (user?.workspaces && !!user?.workspaces?.length) {
      getAllWorkspace()
    }
  })

  useOnClickOutside([wrapperReference, buttonRef], onClose)

  useEffect(() => {
    if (user?.defaultWorkspaceId === activeWorkspace) {
      fetchingData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeWorkspace])

  return (
    <>
      <Transition.Root
        show={isOpen}
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="translate-x-[-280px]"
        enterTo="translate-x-0"
        leave="ease-in duration-300"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-[-280px]"
      >
        <div
          ref={wrapperReference}
          data-testid="workspace-sidebar-wrapper"
          className={classNames(
            'transition-all z-12 duration-200 lg:inset-y-0 lg:pt-5 lg:pb-4 lg:bg-gray-100 border-r border-gray-300 lg:w-[280px] px-5',
            !hideOnFullScreen && (sidebarCollapsed ? 'lg:ml-20' : 'lg:ml-[280px]'),
            hideOnFullScreen ? 'hidden' : 'lg:flex lg:flex-col lg:fixed',
            [!user?.emailVerified && 'notyet-verified mt-12']
          )}
        >
          <div className="flex items-center text-gray-800 gap-x-2">
            <button data-testid="close-workspaces-button" onClick={onClose}>
              <ChevronLeftIcon className="w-5 h-5" />
            </button>
            <span className="font-semibold">Workspaces</span>
          </div>
          <div className="flex flex-col mt-8 overflow-y-auto gap-y-5">
            {workspaces?.map((workspace) => {
              const workspaceData = new DataMapper().workspace(workspace)
              return (
                <div key={workspaceData._id} className="cursor-pointer">
                  <WorkspaceIdentity
                    key={workspaceData._id}
                    menuButtonType="detail"
                    title={workspaceData.displayName}
                    subtitle={pluralize(workspaceData.members.length, 'Member')}
                    onClick={() => handleWorkspaceSelect(workspaceData._id)}
                    useIndigoBackground
                    hasMenuButton={false}
                  />
                </div>
              )
            })}
            {isAdmin && (
              <Button
                className="flex !p-0 !bg-transparent !text-gray-500 hover:!text-indigo-600 focus:!ring-0 focus:!ring-offset-0 !shadow-none"
                onClick={onWorkspaceModalShow}
              >
                <PlusIcon className="w-5 h-5 mr-3" /> Add new workspace
              </Button>
            )}
          </div>
        </div>
      </Transition.Root>

      <WorkspaceModal
        firstStepTitle={['Create a workspace']}
        firstStepDescription="What would you like to name it? You can change this anytime."
        secondStepTitle={['Invite your team to collaborate']}
        secondStepDescription="Enter their email addresses here, and we'll send them an invite to join your new workspace on Triniti."
        onCreateWorkspaceDone={refreshData}
        onWorkspaceModalClose={onWorkspaceModalClose}
        isVisible={isWorkspaceModalVisible}
        isCloseable
        showVisibilityComponent={false}
      />
    </>
  )
}
