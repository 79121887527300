import classNames from 'classnames'

const sizes = {
  xs: 'h-6 w-6',
  sm: 'h-8 w-8',
  md: 'h-10 w-10',
  lg: 'h-12 w-12',
  xl: 'h-14 w-14',
  xxl: 'w-32 h-32',
  full: 'w-full h-full'
}

const fonts = {
  xs: 'text-xs',
  sm: 'text-sm',
  md: '',
  lg: 'text-lg',
  xl: 'text-xl',
  xxl: 'text-4xl'
}

export default function AvatarInitials({ size = 'sm', initials, useIndigoBackground, className, ...rest }) {
  const sizeClassNames = sizes[size]
  const fontClassName = fonts[size]

  return (
    <span
      className={classNames(
        sizeClassNames,
        'inline-flex items-center justify-center rounded-md flex-shrink-0',
        className,
        {
          'bg-indigo-500': useIndigoBackground,
          'bg-gray-500': !useIndigoBackground
        }
      )}
      data-testid="avatar-initial"
      {...rest}
    >
      <span className={classNames(fontClassName, 'font-medium leading-none text-white')}>{initials.toUpperCase()}</span>
    </span>
  )
}
