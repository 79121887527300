import { useState } from 'react'
import { API_METHODS, API_SERVICES } from '../../utils/constants'
import useFetchAPI from '../useFetchAPI'
import PayloadNormalizer from '../../utils/payloadNormalizer'

const useJourney = () => {
  const [getAllJourney, isLoadingAllJourney, errorGettingAllJourney, allJourney, allJourneyUtils] = useFetchAPI(
    API_METHODS.GET_ALL,
    API_SERVICES.JOURNEY
  )
  const [getOneJourney, isLoadingOneJourney, errorGettingOneJourney, oneJourney, oneJourneyUtils] = useFetchAPI(
    API_METHODS.GET_ONE,
    API_SERVICES.JOURNEY
  )
  const [createJourneyAPI, isCreatingJourney, errorCreatingJourney, createJourneyState, createJourneyUtils] =
    useFetchAPI(API_METHODS.CREATE, API_SERVICES.JOURNEY)
  const [removeJourney, isRemovingJourney, errorRemovingJourney] = useFetchAPI(API_METHODS.DELETE, API_SERVICES.JOURNEY)
  const [updateJourneyAPI, isUpdatingJourney, errorUpdatingJourney] = useFetchAPI(
    API_METHODS.UPDATE,
    API_SERVICES.JOURNEY
  )

  const [activeStep, setActiveStep] = useState()
  const [activeStage, setActiveStage] = useState()
  const [members, setMembers] = useState([])
  const [isCurrentMemberEditor, setCurrentMemberEditor] = useState(false)
  const [defaultTabIndex, setDefaultTabIndex] = useState(0)
  const [typeForStepCounter, setTypeForStepCounter] = useState()

  // NOTE: Uncomment this useStete if we need it back
  // const [stepStatusVisible, setStepStatusVisibility] = useState(true)
  const stepStatusVisible = true
  const setStepStatusVisibility = () => false

  const payloadNormalizer = new PayloadNormalizer()

  const createJourney = (payload, ...restArgument) => {
    const payloadNormalized = payloadNormalizer.normalizeJourney(payload)
    return createJourneyAPI(payloadNormalized, ...restArgument)
  }

  const updateJourney = (id, payload, ...restArgument) => {
    const payloadNormalized = payloadNormalizer.normalizeJourney(payload)
    return updateJourneyAPI(id, payloadNormalized, ...restArgument)
  }

  const getOneUtils = {
    ...oneJourneyUtils,
    compareChangesAndUpdate: ({ socket, organizationId, workspaceId }) => {
      const payloadTransformer = (payload) => payloadNormalizer.normalizeJourney(payload)
      const customUpdater = async ({ service, id, payload }) => {
        if (service === API_SERVICES.JOURNEY) {
          const finalPayload = {
            ...payload,
            organizationId,
            workspaceId
          }
          await socket.request({
            path: `/journeys/${id}`,
            method: 'PATCH',
            payload: finalPayload,
            headers: {
              'Content-Type': 'application/json'
            }
          })
        }
      }
      return oneJourneyUtils.compareChangesAndUpdate(payloadTransformer, socket && customUpdater)
    }
  }

  const allMaps = allJourney?.map((journey) => {
    return {
      ...journey,
      type: journey?.type?.replace('Journey', 'Map')
    }
  })

  return {
    getAll: {
      action: getAllJourney,
      isLoading: isLoadingAllJourney,
      error: errorGettingAllJourney,
      result: allMaps || [],
      utils: allJourneyUtils
    },
    getOne: {
      action: getOneJourney,
      isLoading: isLoadingOneJourney,
      error: errorGettingOneJourney,
      result: oneJourney,
      utils: getOneUtils
    },
    create: {
      action: createJourney,
      isLoading: isCreatingJourney,
      error: errorCreatingJourney,
      result: createJourneyState,
      utils: createJourneyUtils
    },
    remove: {
      action: removeJourney,
      isLoading: isRemovingJourney,
      error: errorRemovingJourney
    },
    update: {
      action: updateJourney,
      isLoading: isUpdatingJourney,
      error: errorUpdatingJourney
    },
    activeStep,
    activeStage,
    setActiveStep,
    setActiveStage,
    stepStatusVisible,
    setStepStatusVisibility,
    members,
    setMembers,
    isCurrentMemberEditor,
    setCurrentMemberEditor,
    defaultTabIndex,
    setDefaultTabIndex,
    typeForStepCounter,
    setTypeForStepCounter
  }
}

export default useJourney
