class PayloadNormalizer {
  transformSinglePersona(persona) {
    const { avatarUrl, role, displayName, color, description, _id } = persona || {}
    return {
      displayName,
      color,
      description,
      role,
      avatarUrl,
      ...(_id && { _id })
    }
  }

  transformSingleProblem(problem) {
    const { name, description, totalJourneys, totalPersonas, _id, personas, status } = problem || {}
    return {
      name,
      description,
      totalJourneys,
      totalPersonas,
      personas,
      status,
      ...(_id && { _id })
    }
  }

  transformSingleOpportunity(opportunity) {
    const { name, description, totalJourneys, totalPersonas, status, priority, _id } = opportunity || {}
    return {
      name,
      description,
      totalJourneys,
      totalPersonas,
      status,
      priority,
      ...(_id && { _id })
    }
  }

  transformSingleJourney(journey, excludeDocuments = false) {
    const {
      name,
      description,
      totalPersonas,
      totalPhases,
      totalProblems,
      totalOpportunities,
      _id,
      totalIssues,
      documents
    } = journey || {}
    return {
      name,
      description,
      totalPersonas,
      totalPhases,
      totalProblems,
      totalIssues,
      totalOpportunities,
      ...(excludeDocuments ? {} : { documents }),
      ...(_id && { _id })
    }
  }

  transformSingleStep(step) {
    const {
      chosen,
      description,
      externalLinks,
      id,
      images,
      documents,
      integrationStatus,
      interchange,
      issuesCount,
      issuesStatusCount,
      jiraIssueKeys,
      level,
      linkedTo,
      metadata,
      mode,
      name,
      parentId,
      selected,
      state,
      tags,
      type,
      createdAt,
      assignee,
      status
    } = step || {}

    const temporaryStep = {
      chosen,
      description,
      externalLinks,
      id,
      images,
      documents,
      integrationStatus,
      interchange,
      issuesCount,
      issuesStatusCount,
      jiraIssueKeys,
      level,
      linkedTo,
      metadata,
      mode,
      name,
      parentId,
      selected,
      state,
      tags,
      type,
      createdAt,
      assignee,
      status
    }

    if (step?.personas?.length) {
      temporaryStep.personas = step.personas?.map(this.transformSinglePersona)
    }
    if (step?.problems?.length) {
      temporaryStep.problems = step.problems?.map(this.transformSingleProblem)
    }
    if (step?.opportunities?.length) {
      temporaryStep.opportunities = step.opportunities?.map(this.transformSingleOpportunity)
    }
    if (step?.issues?.length) {
      temporaryStep.issues = step.issues
    }
    if (step?.comments?.length) {
      temporaryStep.comments = step.comments
    }
    if (step?.steps?.length) {
      temporaryStep.steps = step.steps?.map((step) => this.transformSingleStep(step))
    }

    return Object.keys(temporaryStep).reduce((accumulator, key) => {
      if (temporaryStep[key] !== undefined) {
        accumulator[key] = temporaryStep[key]
      }
      return accumulator
    }, {})
  }

  normalizeJourney(payload) {
    const temporaryPayload = { ...payload }
    if (temporaryPayload.personas?.length) {
      temporaryPayload.personas = temporaryPayload.personas?.map(this.transformSinglePersona)
    }
    if (temporaryPayload.problems?.length) {
      temporaryPayload.problems = temporaryPayload.problems?.map((problem) => {
        if (problem?.personas?.length) {
          problem.personas = problem.personas?.map(this.transformSinglePersona)
        }
        return this.transformSingleProblem(problem)
      })
    }
    if (temporaryPayload.issues?.length) {
      temporaryPayload.issues = temporaryPayload.issues?.map((issue) => {
        return issue
      })
    }
    if (temporaryPayload.comments?.length) {
      temporaryPayload.comments = temporaryPayload.comments?.map((comment) => {
        return comment
      })
    }
    if (temporaryPayload.opportunities?.length) {
      temporaryPayload.opportunities = temporaryPayload.opportunities?.map(this.transformSingleOpportunity)
    }
    if (temporaryPayload.phases?.length) {
      temporaryPayload.phases = temporaryPayload.phases?.map((phase) => {
        if (phase.problems?.length) {
          phase.problems = phase.problems?.map((problem) => {
            if (problem?.personas?.length) {
              problem.personas = problem.personas?.map(this.transformSinglePersona)
            }
            return this.transformSingleProblem(problem)
          })
        }

        if (phase.issues?.length) {
          phase.issues = phase.issues?.map((issue) => {
            return issue
          })
        }

        if (phase.comments?.length) {
          phase.comments = phase.comments?.map((comment) => {
            return comment
          })
        }

        return this.transformSingleStep(phase)
      })
    }

    return temporaryPayload
  }

  persona(payload) {
    const temporaryPayload = { ...payload }
    if (temporaryPayload.journeys?.length) {
      temporaryPayload.journeys = temporaryPayload.journeys?.map(this.transformSingleJourney)
    }
    if (temporaryPayload.problems?.length) {
      temporaryPayload.problems = temporaryPayload.problems?.map((problem) => {
        if (problem.personas?.length) {
          problem.personas = problem.personas?.map(this.transformSinglePersona)
        }
        return this.transformSingleProblem(problem)
      })
    }
    if (temporaryPayload.opportunities?.length) {
      temporaryPayload.opportunities = temporaryPayload.opportunities?.map(this.transformSingleOpportunity)
    }

    return temporaryPayload
  }

  problem(payload) {
    const temporaryPayload = { ...payload }
    if (temporaryPayload.journeys?.length) {
      temporaryPayload.journeys = temporaryPayload.journeys?.map(this.transformSingleJourney)
    }
    if (temporaryPayload.personas?.length) {
      temporaryPayload.personas = temporaryPayload.personas?.map(this.transformSinglePersona)
    }

    return temporaryPayload
  }

  opportunity(payload) {
    const temporaryPayload = { ...payload }
    if (temporaryPayload.journeys?.length) {
      temporaryPayload.journeys = temporaryPayload.journeys?.map(this.transformSingleJourney)
    }
    if (temporaryPayload.personas?.length) {
      temporaryPayload.personas = temporaryPayload.personas?.map(this.transformSinglePersona)
    }

    return temporaryPayload
  }

  issue(payload) {
    const temporaryPayload = { ...payload }
    if (temporaryPayload.journeys?.length) {
      temporaryPayload.journeys = temporaryPayload.journeys?.map((journey) =>
        this.transformSingleJourney(journey, true)
      )
    }
    if (temporaryPayload.personas?.length) {
      temporaryPayload.personas = temporaryPayload.personas?.map(this.transformSinglePersona)
    }

    return temporaryPayload
  }
}

export default PayloadNormalizer
