// istanbul ignore file
import React, { useEffect, useRef, useState } from 'react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { ViewColumnsIcon } from '@heroicons/react/24/solid'
import { ViewColumnsIcon as ViewColumnsIconOutline } from '@heroicons/react/24/outline'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import TooltipComposer from '../ui/tooltip/TooltipComposer'
import { navigations } from '../../navigations'
import { useKanbanViewConfig } from '../../hooks/useKanbanViewConfig'
import ConfirmationModal from '../ui/modal/ConfirmationModal'
import { ACTIONS } from '../../utils/constants'
import { check } from '../permissions/Can'
import SidebarLink from './SidebarLink'

export default function SidebarNavigationDesktop({
  user,
  permissions,
  sidebarCollapsed,
  onClick,
  isOpen,
  handleIntercomClick
}) {
  const isNotViewer = check(permissions)
    .for([ACTIONS.UPDATE_OPPORTUNITY, ACTIONS.CREATE_PROBLEM, ACTIONS.CREATE_OPPORTUNITY, ACTIONS.UPDATE_PROBLEM])
    .evaluate()
  const routerLocation = useLocation()
  const navigate = useNavigate()
  const { boards, updateBoard, deleteBoard } = useKanbanViewConfig()
  const [removeBoard, setRemoveBoard] = useState('')
  const [removeModal, setRemoveModal] = useState(false)
  const [editableId, setEditableId] = useState('')

  const isActive = (href, matchNestedPath) => {
    if (matchNestedPath) {
      return routerLocation.pathname.includes(href)
    }
    return routerLocation.pathname === `/${href}`
  }
  const inputReferences = useRef({})

  const handleSaveName = (event, boardData) => {
    const currentBoard = boards?.find((board) => board.id === boardData.id)
    const date = Date.now()
    const newData = {
      ...currentBoard,
      name: event.target.value,
      updatedAt: date
    }

    updateBoard(currentBoard.id, newData)
    setEditableId('')
  }

  const handleRename = (selectedData) => {
    setEditableId(selectedData)
    if (inputReferences.current[selectedData]) {
      inputReferences.current[selectedData].focus()
    }
  }

  const handleMenuSelect = (value, selectedData) => {
    switch (value) {
      case 'rename-board': {
        handleRename(selectedData)
        break
      }
      case 'remove-board': {
        setRemoveBoard(selectedData)
        setRemoveModal(true)
        break
      }
      default: {
        break
      }
    }
  }

  const handleDelete = () => {
    deleteBoard(removeBoard)
    setRemoveModal(false)
    setRemoveBoard('')
    navigate('/tasks')
  }

  const generateNavigationItems = (boardNav) => {
    return boardNav.map(
      (board) =>
        board.orgId === user.orgIds[0] && {
          id: board.id,
          name: board.name,
          href: `tasks/details/${board.name.replaceAll(/\s+/g, '-')}`,
          filter: board.filter,
          icon: ViewColumnsIcon,
          outlineIcon: ViewColumnsIconOutline,
          current: false,
          menuButton: true
        }
    )
  }

  const improvementViews = generateNavigationItems(boards)
  const improvementViewsFormatted = improvementViews?.map((item) => ({
    ...item,
    isCustomImprovementView: true
  }))
  const boardViewIndex = navigations.findIndex((item) => item.isBoardView)
  const navigationWithCustomBoardView = [
    ...navigations.slice(0, boardViewIndex + 1),
    ...improvementViewsFormatted,
    ...navigations.slice(boardViewIndex + 1)
  ]

  return (
    <nav className="flex flex-col justify-between h-full px-3 mt-4" data-testid="sidebar-navigation">
      <div className="space-y-1">
        {navigationWithCustomBoardView?.map((item, index) => {
          if (item.isCustomImprovementView) {
            return (
              <SidebarLink
                key={`${item.href}-${index}`}
                readOnly={!isNotViewer}
                link={item}
                isActive={isActive(item.href)}
                onClick={onClick}
                sidebarCollapsed={sidebarCollapsed}
                editableId={editableId}
                inputRefs={inputReferences}
                handleSaveName={handleSaveName}
                handleMenuSelect={handleMenuSelect}
                className="ml-0 text-gray-800"
              />
            )
          }
          return (
            <React.Fragment key={`${item.name}-${index}`}>
              <Link
                to={item.href}
                onClick={item.isIntercom ? handleIntercomClick : onClick}
                className={classNames(
                  'sidebar-item',
                  `sidebar-item-${item?.name}`,
                  'group w-full flex items-center px-2 py-2 my-2 lg:text-sm text-base leading-5 font-medium rounded-md',
                  {
                    'bg-indigo-100 text-indigo-600': isActive(item.href, item.matchNestedPath),
                    'text-gray-800 hover:bg-indigo-50': !isActive(item.href, item.matchNestedPath)
                  }
                )}
                state={{ title: item.name }}
                aria-current={item.current ? 'page' : undefined}
                data-testid="navigation-link"
                data-tooltip-content={item?.name}
                id={item.isIntercom && 'intercom_button'}
              >
                {/* Sidebar item icon */}
                {isActive(item.href, item.matchNestedPath) ? (
                  <item.icon
                    className={classNames('text-indigo-700 mr-3 flex-shrink-0 h-6 w-6 transition-all', {
                      'ml-2': sidebarCollapsed,
                      'ml-0': !sidebarCollapsed
                    })}
                    aria-hidden="true"
                  />
                ) : (
                  <item.outlineIcon
                    className={classNames(
                      'mr-3 flex-shrink-0 h-6 w-6 transition-all',
                      {
                        'ml-2': sidebarCollapsed,
                        'ml-0': !sidebarCollapsed,
                        'text-gray-800': !item.customColor
                      },
                      item.customColor
                    )}
                    aria-hidden="true"
                  />
                )}

                {/* Sidebar item name */}
                <span
                  className={classNames(item.customColor, 'transition-opacity', 'whitespace-nowrap', {
                    'opacity-0': sidebarCollapsed,
                    'opacity-100': !sidebarCollapsed
                  })}
                >
                  {item.name}
                </span>

                {/* Sidebar item chevron (if child is available) */}
                {item.children && (
                  <span className="ml-auto">
                    <ChevronDownIcon
                      className={classNames('w-5 h-5 transition-transform', { 'rotate-180': isActive(item.href) })}
                    />
                  </span>
                )}
              </Link>

              {/* {item.children &&
              isActive(item.href) &&
              item.children?.map((child, index) => (
                <React.Fragment key={`${child.name}-${index}`}>
                  {child?.name === 'Create a View' && (
                    <>
                      {improvementViews.map((child, index) => (
                        <SidebarLink
                          key={`${child.href}-${index}`}
                          readOnly={!isNotViewer}
                          link={child}
                          isActive={isActive(child.href)}
                          onClick={onClick}
                          sidebarCollapsed={sidebarCollapsed}
                          editableId={editableId}
                          inputRefs={inputReferences}
                          handleSaveName={handleSaveName}
                          handleMenuSelect={handleMenuSelect}
                        />
                      ))}
                      {isNotViewer && !sidebarCollapsed && (
                        <PopoverImprovement
                          type={Link}
                          child={child}
                          options={otherNavigations}
                          onChange={(e) => navigate(`${e.href}`)}
                          title={
                            <span className="flex text-gray-400">
                              <child.icon className="h-5 mr-4 w-h" /> Create a View
                            </span>
                          }
                          containerClass="pl-4"
                          buttonClass="group flex w-full items-center text-gray-500 hover:bg-indigo-50 outline-none px-2 py-2 lg:text-sm text-base leading-5 font-medium rounded-md whitespace-nowrap"
                          optionClass="p-0 list-none hover:cursor-pointer"
                          optionColor="text-gray-500"
                          position="relative"
                        />
                      )}
                    </>
                  )}
                  {child?.name !== 'Create a View' && (
                    <SidebarLink
                      key={`${child.href}-${index}`}
                      link={child}
                      isActive={isActive(child.href)}
                      onClick={onClick}
                      sidebarCollapsed={sidebarCollapsed}
                    />
                  )}
                </React.Fragment>
              ))} */}

              {sidebarCollapsed && !!item.children?.length && (
                <TooltipComposer
                  selector={`.sidebar-item-${item?.name}`}
                  place="right"
                  noArrow={true}
                  customStyle={{ fontSize: '12px', padding: '8px' }}
                  customClass="!rounded !bg-background-500 ml-1.5"
                />
              )}
            </React.Fragment>
          )
        })}
      </div>
      {sidebarCollapsed && (
        <div className="pb-5">
          <TooltipComposer
            selector=".sidebar-item"
            place="right"
            noArrow={true}
            customStyle={{ fontSize: '12px', padding: '8px' }}
            customClass="!rounded !bg-background-500 ml-1.5"
          />
        </div>
      )}
      <ConfirmationModal
        title="View"
        type="delete"
        modalOpen={removeModal}
        setModalOpen={setRemoveModal}
        handleConfirm={handleDelete}
        displayName="this view"
      />
    </nav>
  )
}
