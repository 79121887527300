import FallbackUserImage from '../../../assets/images/user.png'
import AvatarImage from './Image'
import AvatarInitials from './Initials'

export default function Avatar({
  size = 'sm',
  imageUrl,
  initials,
  customFallback,
  className,
  useIndigoBackground,
  ...rest
}) {
  if (imageUrl) {
    return <AvatarImage size={size} imageUrl={imageUrl} className={className} {...rest} />
  }

  if (initials) {
    return (
      <AvatarInitials
        size={size}
        initials={initials}
        className={className}
        useIndigoBackground={useIndigoBackground}
        {...rest}
      />
    )
  }

  return (
    <AvatarImage
      size={size}
      imageUrl={customFallback ? customFallback : FallbackUserImage}
      className={className}
      {...rest}
    />
  )
}
