import { useEffect } from 'react'
import { useAuth } from '../../context/AuthProvider'
import useFreshAccount from '../../hooks/useFreshAccount'

const CheckIfComingFromResetPassword = () => {
  const { success, message } = useFreshAccount()
  const { signout } = useAuth()
  useEffect(() => {
    if (success && message?.includes('You can now login to the application')) {
      signout()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, message])
  return null
}

export default CheckIfComingFromResetPassword
