import { NovuProvider } from '@novu/notification-center'
import useAuthProvider from '../hooks/useAuthProvider'
import { REACT_APP_NOVU_APP_ID } from '../utils/constants'

const NotificationProvider = ({ children }) => {
  const { user } = useAuthProvider()
  return (
    <NovuProvider
      stores={[
        {
          query: {
            limit: 50
          }
        }
      ]}
      styles={{
        header: {
          root: {
            '.mantine-Badge-root': {
              background: '#6466E9'
            }
          }
        },
        footer: { root: { display: 'none' } },
        notifications: {
          root: {
            '.nc-bell-button-dot rect': {
              fill: '#6466E9'
            }
          },
          listItem: {
            unread: {
              '::before': { background: '#6466E9' },
              fontWeight: 'normal',
              fontSize: 14,
              backgroundColor: '#F3F4F6',
              boxShadow: 'none',
              color: '#1F2937'
            },
            read: {
              backgroundColor: '#F3F4F6',
              color: '#1F2937'
            },
            timestamp: {
              color: '#6B7280',
              fontSize: 14
            }
          }
        },
        loader: {
          root: {
            stroke: '#6466E9'
          }
        },
        accordion: {
          content: {
            '.mantine-Switch-input:checked~label': {
              backgroundColor: '#6466E9',
              backgroundImage: 'none'
            }
          }
        }
      }}
      subscriberId={user?.uid}
      subscriberHash={user?.hmac}
      applicationIdentifier={REACT_APP_NOVU_APP_ID}
    >
      {children}
    </NovuProvider>
  )
}

export default NotificationProvider
