import { useState } from 'react'
import { XMarkIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'

export default function MultiSelectInput({
  id,
  label,
  labelSize,
  placeholder,
  className,
  onChange,
  actionComponent,
  value = [],
  customTag
}) {
  const [inputValue, setInputValue] = useState('')
  const [isInputActive, setInputActive] = useState(false)

  const handleSaveTag = (e) => {
    e.preventDefault()
    const textValue = e.target.value.trim()

    /* istanbul ignore else */
    if (!textValue || textValue === '') {
      setInputValue('')
    } else if (value && !value.includes(textValue)) {
      onChange([...value, textValue])
      setInputValue('')
    }

    e.target.value = ''
  }

  const handleKeyDown = (e) => {
    // handle onEnter too
    if (e.key === ' ' || e.key === ',' || e.key === 'Enter') {
      handleSaveTag(e)
    }
  }

  const handleOnFocus = (e) => {
    setInputActive(true)
  }

  const handleOnBlur = (e) => {
    handleSaveTag(e)
    setInputActive(false)
  }

  const handleRemoveTag = (tag) => {
    onChange(value.filter((t) => t !== tag))
  }

  return (
    <div>
      {label && (
        <label
          htmlFor={id}
          className={classNames('block', {
            'text-sm font-medium text-gray-700': !labelSize,
            [labelSize]: labelSize
          })}
        >
          {label}
        </label>
      )}
      <div
        className={classNames('flex mt-1 overflow-hidden border border-gray-300 rounded-md bg-gray-50', {
          'border-indigo-500': isInputActive
        })}
      >
        <div className={classNames('px-3 py-2 flex-grow', { [className]: className })}>
          <div className="flex flex-wrap gap-2">
            {value.map((tag) => (
              <div key={tag} className="flex-grow-0 flex-shrink-0">
                <span
                  className={classNames({
                    'flex items-center px-2 py-0 text-gray-600 border rounded-md border-pale-ash-400': !customTag,
                    [customTag]: customTag
                  })}
                >
                  {tag}
                  <button
                    data-testid="remove-tag-button"
                    className="ml-1 text-lg bg-transparent border-none cursor-pointer text-pale-ash-400 hover:text-pale-ash-800 focus:outline-none"
                    onClick={() => handleRemoveTag(tag)}
                  >
                    <XMarkIcon className="w-5 h-5" />
                  </button>
                </span>
              </div>
            ))}
            <input
              id={id}
              data-testid="input-multi-select"
              type="text"
              className={classNames(
                'placeholder-gray-400 text-gray-600 text-sm px-0 py-1 box-content h-[20px] border-none outline-none focus:ring-0 bg-gray-50',
                {
                  'w-full': value.length === 0
                }
              )}
              placeholder={value.length === 0 && (placeholder ?? 'Type email')}
              onBlur={handleOnBlur}
              onFocus={handleOnFocus}
              onKeyDown={handleKeyDown}
              size={Math.max(1, Math.min(inputValue.length, 25))}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </div>
        </div>
        {actionComponent && (
          <div className="flex items-center flex-shrink-0 bg-white border-l border-gray-300 height-full">
            {actionComponent}
          </div>
        )}
      </div>
    </div>
  )
}
