/* eslint-disable max-lines-per-function */
import React, { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { PencilSquareIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'

import Button from '../button/Button'
import DialogAlert from '../../alert/DialogAlert'

const Modal = ({
  children,
  open = false,
  onClose,
  hideCloseButton,
  padding,
  maxWidth,
  height,
  bgColor,
  readOnly,
  setReadOnly,
  className,
  editor,
  disableBackdropClick,
  showAlert,
  setShowAlert,
  alert
}) => {
  const initialFocusReference = useRef(null)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-16"
        initialFocus={initialFocusReference}
        onClose={() => onClose && !disableBackdropClick && onClose()}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-black/50" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto" data-testid="modal-dialog">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                data-testid="dialog-panel"
                className={classNames(
                  'relative transform overflow-hidden rounded-lg p-6 text-left transition-all sm:my-8 sm:w-full',
                  {
                    [className]: className,
                    'p-6': !padding,
                    [padding]: padding,
                    'sm:max-w-[600px]': !maxWidth,
                    [maxWidth]: maxWidth,
                    'bg-white': !bgColor,
                    [bgColor]: bgColor,
                    [height]: height
                  }
                )}
              >
                <div ref={initialFocusReference} tabIndex={-1} style={{ position: 'absolute', opacity: 0 }} />
                {children}
                <div className="absolute flex space-x-4 top-6 right-6">
                  {readOnly && editor && (
                    <Button
                      color="dark"
                      className="flex items-center space-x-1"
                      customSize="px-[13px] py-1 rounded-2xl"
                      onClick={() => setReadOnly(!readOnly)}
                      data-testid="edit-btn"
                    >
                      <PencilSquareIcon className="w-4 h-4 text-white stroke-2" />{' '}
                      <span className="items-center text-xs font-medium leading-4">Edit</span>
                    </Button>
                  )}
                  <button
                    type="button"
                    className="bg-white rounded-md focus:outline-none focus:ring-0"
                    onClick={() => onClose && onClose()}
                    data-testid="modal-close-button"
                  >
                    <span className="sr-only">Close</span>
                    {!hideCloseButton && <XMarkIcon className="w-5 h-5 text-gray-500 stroke-2" aria-hidden="true" />}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
        {showAlert && (
          <DialogAlert
            type={alert.type}
            title={alert.title}
            message={alert.message}
            onClose={() => setShowAlert(false)}
          />
        )}
      </Dialog>
    </Transition.Root>
  )
}

const Title = ({ children }) => {
  return <h1 className="text-lg font-semibold">{children}</h1>
}

const Body = ({ children, className, margin }) => {
  return (
    <div className={classNames({ 'my-6': !margin, [margin]: margin, [className]: className })} data-testid="modal-body">
      {children}
    </div>
  )
}

const Footer = ({ children, className, ...properties }) => {
  return (
    <div className={className} {...properties}>
      {children}
    </div>
  )
}

Modal.Title = Title
Modal.Body = Body
Modal.Footer = Footer

export default Modal
