/* eslint-disable indent */
import React from 'react'
import classNames from '../../../utils/text/classnames'
const sizes = {
  sm: 'px-2.5 py-1.5 text-xs rounded',
  md: 'px-3 py-2 text-sm rounded-md',
  lg: 'px-4 py-2 rounded-md',
  block: 'py-2 w-full rounded-md',
  icon: 'p-3 rounded-full',
  square: 'p-2 rounded-md'
}

const solidColors = {
  primary:
    'border border-transparent text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-indigo-500',
  secondary:
    'border border-transparent text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-indigo-500',
  dark: 'border border-transparent bg-gray-800 text-white hover:bg-gray-700 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-background-500',
  white:
    'border border-transparent bg-white text-black hover:bg-gray-200 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-gray-200',
  grey: 'border border-transparent text-white bg-pale-ash-500 hover:bg-pale-ash-600 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-pale-ash-700',
  darkGrey: 'border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:ring-0',
  lightGrey: 'border border-transparent bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-0',
  grayIndigo:
    'border border-transparent transition duration-200 text-grey-800 bg-gray-100 hover:bg-indigo-100 focus:outline-none focus:ring-0',
  coolGray:
    'border border-transparent bg-gray-800 text-white hover:bg-gray-700 disabled:bg-gray-300 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-background-500'
}

const outlineColors = {
  primary:
    'text-indigo-600 border border-indigo-600 hover:border-indigo-900 hover:text-indigo-900 bg-white focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-indigo-500',
  secondary:
    'text-indigo-700 border border-indigo-100 hover:border-indigo-300 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-indigo-500',
  dark: 'border border-background-500 text-background-500 hover:border-background-400 hover:text-background-400 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-background-500',
  white:
    'border border-gray-300 bg-white text-black hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-gray-200',
  grey: 'text-pale-ash-500 border-pale-ash-300 hover:border-pale-ash-600 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-pale-ash-700'
}

export default function Button({
  type = 'button',
  color = 'primary',
  size = 'md',
  variant = 'solid',
  spacing,
  onClick,
  children,
  className,
  disabled,
  customSize,
  customColor,
  innerRef,
  ...properties
}) {
  const actualSize = sizes[size]
  const actualColor = customColor || (color && variant === 'solid' ? solidColors[color] : outlineColors[color])

  return (
    <button
      ref={innerRef}
      type={type}
      onClick={onClick}
      className={classNames(
        customSize || actualSize,
        actualColor,
        spacing,
        className,
        disabled && 'cursor-not-allowed bg-opacity-50 hover:bg-opacity-60',
        'font-medium shadow-sm'
      )}
      disabled={disabled}
      data-testid="button"
      {...properties}
    >
      {children}
    </button>
  )
}
