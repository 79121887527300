import { API_METHODS } from '../../utils/constants'
import useFetchComment from '../useFetchComment'

const useComment = () => {
  const [createCommentAPI, isCreatingComment, errorCreatingComment, createCommentState, createCommentUtils] =
    useFetchComment(API_METHODS.CREATE)

  const [updateCommentAPI, isUpdatingComment, errorUpdatingComment] = useFetchComment(API_METHODS.UPDATE)

  const [removeCommentAPI, isRemovingComment, errorRemovingComment] = useFetchComment(API_METHODS.DELETE)

  const createComment = (serviceId, payload, serviceName, subResourcePath, ...restArgument) => {
    return createCommentAPI(serviceId, payload, serviceName, subResourcePath, ...restArgument)
  }

  const updateComment = (serviceId, id, payload, serviceName, subResourcePath, ...restArgument) => {
    return updateCommentAPI(serviceId, id, payload, serviceName, subResourcePath, ...restArgument)
  }

  const removeComment = (serviceId, id, serviceName, subResourcePath, ...restArgument) => {
    return removeCommentAPI(serviceId, id, serviceName, subResourcePath, ...restArgument)
  }

  return {
    create: {
      action: createComment,
      isLoading: isCreatingComment,
      error: errorCreatingComment,
      result: createCommentState,
      utils: createCommentUtils
    },
    update: {
      action: updateComment,
      isLoading: isUpdatingComment,
      error: errorUpdatingComment
    },
    remove: {
      action: removeComment,
      isLoading: isRemovingComment,
      error: errorRemovingComment
    }
  }
}

export default useComment
