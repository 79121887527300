import { createContext } from 'react'
import useOrganization from '../hooks/dataFetching/useOrganization'

export const OrganizationContext = createContext()

const OrganizationProvider = ({ children }) => {
  const organization = useOrganization()
  return <OrganizationContext.Provider value={organization}>{children}</OrganizationContext.Provider>
}

export default OrganizationProvider
