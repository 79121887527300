import { useState, useEffect, useRef } from 'react'
import { logger } from '../utils/logger'

export const useLocalStorage = (key, initialValue) => {
  const readValue = () => {
    // istanbul ignore next
    if (typeof window === 'undefined') {
      return initialValue
    }

    try {
      const item = window.localStorage.getItem(key)
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      logger.warn(`Error reading localStorage key “${key}”:`, error)
      return initialValue
    }
  }

  const value = readValue()
  const [storedValue, changeStoredValue] = useState(value)
  const storedValueReference = useRef(value)

  const setStoredValue = (value) => {
    storedValueReference.current = value
    changeStoredValue(value)
  }

  const setValue = (value) => {
    // istanbul ignore next
    if (typeof window == 'undefined') {
      logger.warn(`Tried setting localStorage key “${key}” even though environment is not a client`)
    }

    try {
      const newValue = value instanceof Function ? value(storedValue) : value

      window.localStorage.setItem(key, JSON.stringify(newValue))
      setStoredValue(newValue)
      window.dispatchEvent(new Event('local-storage'))
    } catch (error) {
      logger.warn(`Error setting localStorage key “${key}”:`, error)
    }
  }

  useEffect(() => {
    setStoredValue(readValue())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const handleStorageChange = () => {
      setStoredValue(readValue())
    }

    window.addEventListener('local-storage', handleStorageChange)

    return () => {
      window.removeEventListener('local-storage', handleStorageChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [storedValue, setValue, storedValueReference]
}
