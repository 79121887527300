import Button from '../ui/button/Button'
import Modal from '../ui/modal/Modal'
import TooltipComposer from '../ui/tooltip/TooltipComposer'

export default function RemoveWorkspaceUserConfirmation({ open, onClose, userToRemove, onUserRemove, isRemoving }) {
  return (
    <Modal open={open} onClose={onClose} maxWidth={'sm:max-w-[500px]'} hideCloseButton>
      <Modal.Body className="mt-0 mb-0">
        <div className="flex" data-testid="remove-workspace-user-confirmation">
          <div>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="40" height="40" rx="20" fill="#FEE2E2" />
              <path
                d="M20 17V19M20 23H20.01M13.0718 27H26.9282C28.4678 27 29.4301 25.3333 28.6603 24L21.7321 12C20.9623 10.6667 19.0378 10.6667 18.268 12L11.3398 24C10.57 25.3333 11.5322 27 13.0718 27Z"
                stroke="#DC2626"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="px-5">
            <div className="text-lg font-medium">Confirmation Needed</div>
            <div className="text-sm text-gray-500 mb-5 mt-2">
              Are you sure you want to remove <span className="font-medium text-gray-800">{userToRemove?.name}</span>{' '}
              from the workspace? Please note, they may lose access to this workspace upon removal.
            </div>
            <div className="flex flex-row justify-end gap-4">
              <Button color="white" variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button
                data-testid="remove-workspace-user-button"
                variant="solid"
                customColor="bg-red-600 hover:bg-red-800 text-white"
                onClick={onUserRemove}
              >
                {isRemoving ? 'Removing' : 'Remove'}
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
      <TooltipComposer selector=".radio-tooltip" delay={100} offset={7} place="bottom" zIndex={10_000} />
    </Modal>
  )
}
