import { useEffect, useRef, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import JwtDecode from 'jwt-decode'
import { CONFIG } from '../utils/constants'
import sanitizeObject from '../utils/sanitizeObject'
import { parsePermissions } from '../components/permissions/Can'
import DataMapper from '../utils/dataMapper'
import { logger } from '../utils/logger'
import { useLocalStorage } from './useLocalStorage'
import useGetToken from './useGetToken'

export default function useAuthProvider() {
  const { user, logout } = useAuth0()
  const [token, setToken] = useState()
  const [permissions, setPermissions] = useState()
  const [userData, changeUserData] = useState()
  const userDataReference = useRef()
  const [activeOrgIndex, setActiveOrgIndex] = useState(0)
  const [activeWorkspaceIndex, changeActiveWorkspaceIndex, activeWorkspaceIndexReference] = useLocalStorage(
    'activeWorkspace',
    0
  )

  const getAuth0Token = useGetToken()
  const tokenReference = useRef()

  const setUserData = (data) => {
    changeUserData(data)
    userDataReference.current = data
  }

  const signout = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin
      }
    })

  const fetchUserData = async (userId) => {
    const auth0Token = await getAuth0Token()
    const response = await fetch(`${CONFIG.BACKEND_BASE_URL}/users/${userId}?notificationAuth=true&disableCache=true`, {
      method: 'GET',
      cache: 'no-store',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth0Token}`
      }
    })
    const result = await response.json()
    if (!response.ok) {
      throw new Error(result.message)
    }
    return result
  }

  const updateUserData = async (userId, userData) => {
    const sanitizedPayload = sanitizeObject(userData)
    const response = await fetch(`${CONFIG.BACKEND_BASE_URL}/users/${userId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(sanitizedPayload)
    })
    const result = await response.json()
    if (!response.ok) {
      throw new Error(result.message)
    }
    return result
  }

  const getToken = async () => {
    try {
      const auth0Token = await getAuth0Token()
      if (!auth0Token) return
      tokenReference.current = auth0Token
      setToken(auth0Token)

      const payload = JwtDecode(tokenReference.current)
      const userId = payload?.sub
      const userData = await fetchUserData(userId)

      const rawPermissions = userData?.permissions || payload?.permissions || []
      const workspaceIndex = activeWorkspaceIndexReference.current
      const workspaceData = new DataMapper().workspace(userData?.user_metadata?.workspaces?.[workspaceIndex])
      const workspacePermissions = rawPermissions?.filter((permission) => permission?.includes(workspaceData.id))
      const parsedPermissions = parsePermissions(workspacePermissions)
      setPermissions(parsedPermissions)
      const finalUserData = formatUser(userData)
      setUserData(finalUserData)
    } catch (error) {
      logger.error(error)
    }
  }

  useEffect(() => {
    if (!user?.sub) return
    getToken()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.sub])

  const getUserData = (userData) => {
    if (!userData) return null
    const workspaceIndex = activeWorkspaceIndexReference.current
    const workspaceData = new DataMapper().workspace(userData?.workspaces?.[workspaceIndex])
    const orgData = new DataMapper().workspace(userData?.organizations?.[activeOrgIndex])
    return {
      ...userData,
      defaultOrg: userData?.orgIds?.[activeOrgIndex],
      defaultOrgName: orgData?.displayName,
      defaultWorkspaceId: workspaceData?.id,
      defaultWorkspaceName: workspaceData?.displayName
    }
  }

  const setActiveWorkspaceIndex = (id) => {
    const userWorkspaces = userData?.workspaces
    const selectedWorkspaceIndex = userWorkspaces.findIndex((workspace) => workspace.id === id)
    changeActiveWorkspaceIndex(selectedWorkspaceIndex)

    const rawPermissions = userData?.metaData?.permissions
    const workspaceData = new DataMapper().workspace(userData?.metaData?.workspaces?.[selectedWorkspaceIndex])
    const workspacePermissions = rawPermissions?.filter((permission) => permission?.includes(workspaceData.id))
    const parsedPermissions = parsePermissions(workspacePermissions)
    setPermissions(parsedPermissions)
  }

  return {
    user: getUserData(userData),
    getUserRefData: () => {
      const data = userDataReference.current
      return getUserData(data)
    },
    permissions,
    signout,
    token,
    refreshToken: getToken,
    updateUserData,
    setActiveOrgIndex,
    setActiveWorkspaceIndex,
    activeWorkspaceIndex,
    activeWorkspaceIndexRef: activeWorkspaceIndexReference,
    fetchUserData
  }
}

function formatUser(user) {
  if (!user) return

  const userMetadata = user?.user_metadata || {}

  const userEmail = user.email?.split('@')[0]
  const userOrganizations = userMetadata?.organizations || []
  const userWorkspaces = userMetadata?.workspaces || []
  const userOrgs = userOrganizations?.map((org) => org.id)

  const isOnboarded = (() => {
    if (!userMetadata?.firstName) {
      return false
    }
    if (!userMetadata?.lastName) {
      return false
    }
    if (!userMetadata?.role) {
      return false
    }
    return true
  })()

  const firstName = userMetadata?.firstName || user.given_name || userEmail
  const lastName = userMetadata?.lastName || user.family_name || userEmail
  const initial = `${firstName?.charAt(0)}${lastName?.charAt(0)}`?.toLowerCase()
  const updatedAvatarUrl = user.picture?.replace(/..\.png$/, `${initial}.png`)

  const decodedUrl = updatedAvatarUrl.replaceAll('%3A', ':').replaceAll('%2F', '/')

  return {
    uid: user.user_id || user.email,
    organizations: userOrganizations,
    workspaces: userWorkspaces,
    orgIds: userOrgs,
    email: user.email,
    emailVerified: user.email_verified,
    firstName,
    lastName,
    role: userMetadata?.role,
    displayName: user.name || `${firstName} ${lastName}`,
    onboarded: isOnboarded,
    photoURL: decodedUrl,
    isAnonymous: user.isAnonymous,
    lastLoginAt: user.last_login || null,
    createdAt: user.created_at,
    updatedAt: user.updated_at,
    metaData: user.user_metadata,
    hmac: user.hmac
  }
}
