// istanbul ignore file
export default class DataMapper {
  workspace(data = {}) {
    return {
      ...data,
      displayName: data.displayName || data.display_name
    }
  }

  // TODO: Refactor this after we fix the linting, this will require a refactor on the useAuthProvider.js file
  // eslint-disable-next-line sonarjs/no-identical-functions
  organization(data = {}) {
    return {
      ...data,
      displayName: data.displayName || data.display_name
    }
  }
}
