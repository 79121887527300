import { useRef, useState } from 'react'
import classNames from 'classnames'

import Avatar from '../ui/avatar/Avatar'
import WorkspaceModal from '../workspace/WorkspaceModal'
import getInitials from '../../utils/getInitials'

import { useAuth } from '../../context/AuthProvider'
import InviteMemberModal from '../workspace/InviteMemberModal'
import RemoveWorkspaceUserConfirmation from '../workspace/RemoveWorkspaceUserConfirmation'
import useWorkspace from '../../hooks/dataFetching/useWorkspace'
import useInviteMemberModal from '../../hooks/useInviteMemberModal'
import useOnClickOutside from '../../hooks/useClickOutside'
import TooltipComposer from '../ui/tooltip/TooltipComposer'
import WorkspaceMenuButton from './WorkspaceMenuButton'

export default function IdentityDropdownMenu({
  buttonRef,
  sidebarCollapsed,
  menuButtonType = 'switchable-workspace',
  title,
  subtitle,
  size,
  useIndigoBackground,
  avatar,
  onClick = () => {},
  isWorkspaceSwitchable,
  setWorkspacePanelOpen = () => {},
  hasMenuButton = true,
  onTitleChange = () => {},
  onTitleFinishChange = () => {},
  isOnEditMode = false,
  showSidebar
}) {
  const { permissions, user } = useAuth()
  const [isWorkspaceModalVisible, setWorkspaceModalVisible] = useState(false)
  const [workspaceModalTabIndex, setWorkspaceModalTabIndex] = useState(false)
  const [isManageMemberVisible, setIsManageMemberVisible] = useState(false)
  const [userToRemove, changeUserToRemove] = useState(null)
  const { removeFromWorkspace, isRemoving } = useWorkspace()
  const { isLoading: isLoadingMemberList, members, changeRole, addMembers, getOneJourney } = useInviteMemberModal()
  const [onEditMode, setOnEditMode] = useState(isOnEditMode)
  const inputReference = useRef(null)

  const openManageMemberModal = () => {
    setIsManageMemberVisible(true)
  }

  const setUserToRemove = (userId, name) => {
    changeUserToRemove({ userId, name })
  }

  const removeUserToRemove = () => {
    changeUserToRemove(null)
  }

  const closeManageMemberModal = () => {
    setIsManageMemberVisible(false)
  }

  const openInviteWorkspaceModal = () => {
    setWorkspaceModalVisible(true)
    setWorkspaceModalTabIndex(2)
  }

  const onWorkspaceModalClose = () => {
    setWorkspaceModalVisible(false)
  }

  const onUserRemove = async () => {
    const workspaceId = user.defaultWorkspaceId
    await removeFromWorkspace({ workspaceId, userId: userToRemove?.userId })
    removeUserToRemove()
    await getOneJourney(workspaceId)
  }

  const handleWorkspaceMenuSelect = (value) => {
    switch (value) {
      case 'create-workspace': {
        setWorkspaceModalVisible(true)
        break
      }
      case 'switch-workspace': {
        setWorkspacePanelOpen(true)
        break
      }
      case 'invite-user': {
        openInviteWorkspaceModal()
        break
      }
      case 'manage-members': {
        openManageMemberModal()
        break
      }
      case 'rename-workspace': {
        showSidebar()
        setOnEditMode(true)
        break
      }
      default: {
        break
      }
    }
  }

  const onFinishEdit = () => {
    if (!onEditMode) {
      return
    }
    setOnEditMode(false)
    onTitleFinishChange()
  }

  const onNameEditKeyDown = (e) => {
    if (e.key === 'Enter') {
      onFinishEdit()
    }
  }

  useOnClickOutside(inputReference, () => {
    onFinishEdit()
  })

  const WorkspaceMenu = ({ icon, handleWorkspaceMenuSelect, classNameButton }) => (
    <WorkspaceMenuButton
      type={isWorkspaceSwitchable ? 'switchable-workspace' : menuButtonType}
      handleSelect={(value) => handleWorkspaceMenuSelect(value)}
      bgColor="hover:bg-indigo-200 hover:!text-gray-500"
      dropdownActiveColor="bg-indigo-500 !text-white"
      ringColor="focus:ring-offset-0 focus:ring-indigo-100"
      classNameMenu="!flex"
      color="text-gray-500 focus:text-gray-500"
      shouldStopPropagation={true}
      minWidth="min-w-[241px]"
      customClassMenuItems="left-[24px] top-[-47px]"
      permissions={permissions}
      icon={icon}
      classNameButton={classNameButton}
    />
  )

  const WorkspaceIcon = () => (
    <Avatar
      size="md"
      className="flex-shrink-0 workspace-identity-wrapper"
      useIndigoBackground={useIndigoBackground}
      initials={getInitials(title)}
      imageUrl={avatar}
      data-tooltip-content={title}
    />
  )

  return (
    <>
      <div
        ref={buttonRef}
        className={classNames({
          'ml-3 relative': size === 'sm',
          'relative text-left': size !== 'sm'
        })}
        data-testid="workspace-identity"
      >
        <div
          className={classNames('text-sm flex items-center hover:bg-indigo-50', {
            'max-w-xs flex items-center rounded-full': size === 'sm',
            'group w-full rounded-md p-2 text-left font-medium text-gray-700 focus:ring-offset-gray-700': size !== 'sm'
          })}
          data-testid="workspace-identity-wrapper"
          onClick={onClick}
        >
          <span className="flex items-center justify-between w-full overflow-hidden text-ellipsis">
            <span className="flex items-center justify-between flex-shrink-0 w-full min-w-0 space-x-3">
              {sidebarCollapsed ? (
                <WorkspaceMenu handleWorkspaceMenuSelect={handleWorkspaceMenuSelect} icon={WorkspaceIcon} />
              ) : (
                <WorkspaceIcon />
              )}

              {onEditMode ? (
                <input
                  className="focus:border-1 focus:border-indigo-500 text-sm shadow !mr-2 !ml-1 appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="workspace"
                  type="text"
                  placeholder="Workspace"
                  value={title}
                  onChange={(e) => onTitleChange(e.target.value)}
                  onKeyDown={onNameEditKeyDown}
                  ref={inputReference}
                  data-testid="profile-user-name-input"
                />
              ) : (
                <>
                  {size !== 'sm' && (
                    <span
                      className={classNames('flex flex-col flex-1 min-w-0 transition-all', {
                        'opacity-0': sidebarCollapsed
                      })}
                    >
                      <span className="text-sm font-semibold text-gray-800 truncate">{title}</span>
                      <span className="text-xs font-normal text-gray-800 truncate" data-testid="profile-user">
                        {subtitle}
                      </span>
                    </span>
                  )}
                </>
              )}
            </span>
          </span>
          {!sidebarCollapsed && hasMenuButton && (
            <WorkspaceMenu
              handleWorkspaceMenuSelect={handleWorkspaceMenuSelect}
              classNameButton={'p-[2px] !flex align-center focus:ring-offset-0 focus:ring-indigo-100 border-none'}
            />
          )}
        </div>
      </div>
      <WorkspaceModal
        firstStepTitle={['Create a workspace']}
        firstStepDescription="What would you like to name it? You can change this anytime."
        secondStepTitle={['Invite your team to collaborate']}
        secondStepDescription="Enter their email addresses here, and we'll send them an invite to join your new workspace on Triniti."
        showVisibilityComponent={false}
        onWorkspaceModalClose={onWorkspaceModalClose}
        isVisible={isWorkspaceModalVisible}
        isCloseable
        workspaceModalTabIndex={workspaceModalTabIndex}
      />
      <InviteMemberModal
        open={isManageMemberVisible}
        onClose={closeManageMemberModal}
        setUserToRemove={setUserToRemove}
        getOneJourney={getOneJourney}
        isLoadingMemberList={isLoadingMemberList}
        addMembers={addMembers}
        changeRole={changeRole}
        members={members}
      />
      <RemoveWorkspaceUserConfirmation
        open={!!userToRemove}
        onClose={removeUserToRemove}
        userToRemove={userToRemove}
        onUserRemove={onUserRemove}
        isRemoving={isRemoving}
      />
      {sidebarCollapsed && (
        <TooltipComposer
          selector=".workspace-identity-wrapper"
          place="right"
          noArrow={true}
          customStyle={{ fontSize: '12px', padding: '8px' }}
          customClass="!rounded !bg-background-500 ml-1.5"
        />
      )}
    </>
  )
}
