/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable max-lines-per-function */
import { useState } from 'react'
import { Menu } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Float } from '@headlessui-float/react'
import classNames from 'classnames'

export default function DropdownButton({
  testid,
  title,
  options,
  icon: Icon,
  buttonAction,
  downIconFalse,
  bgColor,
  color,
  className,
  alignRightItems,
  staticTitle,
  classNameButton,
  preventDefault,
  minWidth,
  downIcon: DownIcon,
  dropdownActiveColor,
  ringColor,
  shouldStopPropagation = true,
  customClassMenuItems,
  disabled,
  customDisplay,
  isTitleHiddenOnMobileView,
  ...properties
}) {
  const [selected, setSelected] = useState()

  const handleSelect = (option) => {
    setSelected(option.type !== 'clear' && option.name)
    buttonAction(option.value)
  }

  return (
    <Menu
      {...properties}
      as="div"
      className={classNames('relative inline-block text-left pointer-events-auto', className)}
      data-testid="dropdown-button-wrapper"
      open={true}
    >
      {({ open }) => (
        <Float
          placement={alignRightItems ? 'bottom-end' : 'bottom-start'}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
          portal
        >
          <div>
            <Menu.Button
              data-testid={testid || 'dropdown-button'}
              className={classNames(
                'justify-center w-full rounded-md border border-grey-300 focus:bg-indigo-500 focus:text-white hover:bg-indigo-100 shadow-sm text-sm font-medium focus:outline-none',
                {
                  [classNameButton]: classNameButton,
                  'px-4 py-2': !classNameButton,
                  [bgColor]: bgColor,
                  'bg-transparent': !bgColor,
                  [color]: color,
                  'text-gray-500': !color,
                  [dropdownActiveColor]: open,
                  [ringColor]: ringColor,
                  [customDisplay]: customDisplay,
                  'inline-flex': !customDisplay
                }
              )}
              onClick={(e) => {
                /* istanbul ignore else */
                if (shouldStopPropagation) {
                  e.stopPropagation()
                }
              }}
              disabled={disabled}
            >
              {Icon && <Icon className="inline w-5 h-5" />}
              {staticTitle ? (
                title && (
                  <span
                    className={classNames({
                      'ml-2': Icon,
                      'ml-0': !Icon,
                      'hidden md:block': isTitleHiddenOnMobileView
                    })}
                  >
                    {title}
                  </span>
                )
              ) : (
                <span
                  className={classNames({ 'ml-2': Icon, 'ml-0': !Icon, 'hidden md:block': isTitleHiddenOnMobileView })}
                >
                  {!selected && title} {selected && selected}
                </span>
              )}

              {!downIconFalse && <ChevronDownIcon className="hidden w-5 h-5 ml-2 -mr-1 md:block" aria-hidden="true" />}
              {DownIcon && <DownIcon className="inline w-5 h-5" />}
            </Menu.Button>
          </div>

          <Menu.Items
            className={classNames(
              'origin-top-right absolute mt-2 w-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10',
              {
                'right-0': alignRightItems,
                'left-0': !alignRightItems,
                [minWidth]: minWidth,
                'min-w-[10rem]': !minWidth,
                [customClassMenuItems]: customClassMenuItems
              }
            )}
            data-testid="dropdown-menu-items"
          >
            <div>
              {options?.map((option) => (
                <Menu.Item key={option.name}>
                  <button
                    className={classNames(
                      'flex px-4 py-2 text-sm w-full whitespace-nowrap first:rounded-tr-md first:rounded-tl-md last:rounded-br-md last:rounded-bl-md',
                      option.className,
                      {
                        'border-t border-pale-ash-300': option.hasBorder,
                        'text-gray-400 cursor-not-allowed': option.disabled,
                        'text-background-500': !option.disabled,
                        [option.hover]: option.hover,
                        'hover:bg-gray-100': !option.hover
                      }
                    )}
                    disabled={option.disabled}
                    onClick={(e) => {
                      /* istanbul ignore if */
                      if (preventDefault) {
                        e.preventDefault()
                      }
                      e.stopPropagation()
                      handleSelect(option, option.type)
                    }}
                    data-testid="dropdown-menu-item"
                  >
                    {option.icon}
                    {option.img}
                    {option.name}
                    {option.iconRight}
                  </button>
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Float>
      )}
    </Menu>
  )
}
