import { useRef, useState } from 'react'
import { useAuth } from '../context/AuthProvider'
import { API_METHODS, CONFIG } from '../utils/constants'
import sanitizeObject from '../utils/sanitizeObject'
import useGetToken from './useGetToken'

const useFetchComment = (method, token) => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const getAuth0Token = useGetToken()
  const { getUserRefData } = useAuth()
  const [result, changeResult] = useState(null)
  const resultReference = useRef(null)
  const originalResultReference = useRef(null)

  const setResult = (value) => {
    resultReference.current = value
    changeResult(value)
  }

  const APICreateComment = async (serviceId, payload, serviceName, subResourcePath = '', alternativeToken = null) => {
    const user = getUserRefData()
    const token = alternativeToken || (await getAuth0Token())
    // Add organizationId and workspaceId to payload
    payload.organizationId = user.defaultOrg
    payload.workspaceId = user.defaultWorkspaceId
    const response = await fetch(
      `${CONFIG.BACKEND_BASE_URL}/${serviceName}/${serviceId}${subResourcePath}/comments?workspaceId=${user.defaultWorkspaceId}&organizationId=${user.defaultOrg}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(payload)
      }
    )
    const result = await response.json()
    if (!response.ok) {
      throw new Error(result.message)
    }
    return result
  }

  const APIUpdateComment = async (
    serviceId,
    id,
    payload,
    serviceName,
    subResourcePath = '',
    alternativeToken = null
  ) => {
    const user = getUserRefData()
    const token = alternativeToken || (await getAuth0Token())
    const satintizedPayload = sanitizeObject(payload)
    const response = await fetch(
      `${CONFIG.BACKEND_BASE_URL}/${serviceName}/${serviceId}${subResourcePath}/comments/${id}?workspaceId=${user.defaultWorkspaceId}&organizationId=${user.defaultOrg}`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(satintizedPayload)
      }
    )
    const result = await response.json()

    if (!response.ok) {
      throw new Error(result.message)
    }

    return result
  }

  const APIDeleteComment = async (serviceId, id, serviceName, subResourcePath = '', alternativeToken = null) => {
    const token = alternativeToken || (await getAuth0Token())
    const user = getUserRefData()

    const response = await fetch(
      `${CONFIG.BACKEND_BASE_URL}/${serviceName}/${serviceId}${subResourcePath}/comments/${id}?workspaceId=${user.defaultWorkspaceId}&organizationId=${user.defaultOrg}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
    )
    const result = await response.json()

    if (!response.ok) {
      throw new Error(result.message)
    }

    return result
  }

  const actionMap = {
    [API_METHODS.CREATE]: APICreateComment,
    [API_METHODS.UPDATE]: APIUpdateComment,
    [API_METHODS.DELETE]: APIDeleteComment
  }

  const action = async (...arguments_) => {
    try {
      setError(null)
      const asyncFunction = actionMap[method]
      if (!asyncFunction) {
        throw new Error('Invalid method')
      }
      setIsLoading(true)
      return await asyncFunction(...arguments_, token)
    } catch (error) {
      setError(error.message)
      throw error
    } finally {
      setIsLoading(false)
    }
  }

  const utils = {
    setResult,
    originalResultRef: originalResultReference,
    setError
  }

  return [action, isLoading, error, result, utils]
}

export default useFetchComment
