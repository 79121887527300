import { useState, useRef } from 'react'
import { QueryParser } from '../models/utils/queryParser'
import { API_METHODS, CONFIG, FEATURE_FLAGS } from '../utils/constants'
import sanitizeObject from '../utils/sanitizeObject'
import { logger } from '../utils/logger'
import { useAuth } from '../context/AuthProvider'
import generateSHA256Hash from '../utils/generateHash'
import { useAlertContext } from '../context/AlertProvider'
import { useErrorCollectorContext } from '../context/ErrorCollectorProvider'
import useGetToken from './useGetToken'
import useFeatureFlag from './useFeatureFlag'

class HttpError extends Error {
  constructor(message, statusCode) {
    super(message) // Call the parent constructor with the message
    this.statusCode = statusCode // Add a statusCode property
    this.name = this.constructor.name // Set the error name to the class name
    Error.captureStackTrace(this, this.constructor) // Capture the stack trace
  }
}

/**
 * Custom hook to fetch data from an API.
 *
 * @param {string} method - The HTTP method to use (e.g., GET_ALL, GET_ONE).
 * @param {string} hookServiceName - The service name for the API endpoint.
 * @param {string} [token] - The authentication token.
 * @param {string} [resourcePath] - The path to the resource.
 * @param {function} [actionOverride] - A function to override the default action. For testing purposes only.
 *
 * @returns {Array} An array containing:
 *  - {function} action: The main action function to call the API.
 *  - {boolean} isLoading: A flag indicating if the request is in progress.
 *  - {string|null} error: Any error messages.
 *  - {Object|null} result: The result of the API call.
 *  - {Object} utils: Utility functions.
 */
const useFetchAPI = (method, hookServiceName, token, resourcePath, actionOverride) => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [result, changeResult] = useState(null)
  const [isSavingDocument, setIsSavingDocument] = useState(false)
  const resultReference = useRef(null)
  const isUpdatingReference = useRef(false)
  const activeUpdateHash = useRef(null)
  const originalResultReference = useRef(null)
  const getAuth0Token = useGetToken()
  const { getUserRefData } = useAuth()
  const errorCollector = useErrorCollectorContext()
  const { value: isAuditLogsActive } = useFeatureFlag(FEATURE_FLAGS.AUDIT_LOGS)

  const alert = useAlertContext()
  const setResult = (value) => {
    resultReference.current = value
    changeResult(value)
  }
  const clearResult = () => {
    resultReference.current = null
    changeResult(null)
  }

  const buildQueryString = (query, user) => {
    const queryParser = QueryParser(query)
    const isProjectionExcluding = !!query?.isProjectionExcluding
    const queryObject = {
      ...(query?.where ? { find: JSON.stringify(queryParser.parseWhere()) } : {}),
      ...(query?.orderBy ? { sort: JSON.stringify(queryParser.parseSort()) } : {}),
      ...(query?.skip ? { skip: queryParser.parseSkip() } : {}),
      ...(query?.limit ? { limit: queryParser.parseLimit() } : {}),
      ...(query?.projection ? { projection: JSON.stringify(queryParser.parseProjection(isProjectionExcluding)) } : {}),
      ...(query?.projectName === undefined ? {} : { projectName: query?.projectName }),
      ...(query?.startingIndex === undefined ? {} : { startingIndex: query?.startingIndex }),
      ...(query?.disableCache ? { disableCache: true } : {}),
      // Add organizationId and workspaceId, and projectName for Jira to query
      workspaceId: user.defaultWorkspaceId,
      organizationId: user.defaultOrg
    }

    return Object.entries(queryObject)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&')
  }

  const APIGetAll = async (serviceName, query = {}, alternativeToken = null, resourcePath = '') => {
    const user = getUserRefData()
    const token = alternativeToken || (await getAuth0Token())

    if (!token) {
      throw new Error('API token does not exist')
    }

    if (!user?.defaultOrg) {
      throw new Error('OrganizationId does not exist')
    }

    if (!user?.defaultWorkspaceId) {
      throw new Error('WorkspaceId does not exist')
    }

    const queryString = buildQueryString(query, user)

    const response = await fetch(`${CONFIG.BACKEND_BASE_URL}/${serviceName}${resourcePath}?${queryString}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })

    const result = await response.json()

    if (!response.ok) {
      throw new Error(result.message)
    }

    return result
  }

  const APIGetAllWithPayload = async (serviceName, query = {}, options = {}) => {
    const user = getUserRefData()
    const token = await getAuth0Token()

    if (!token) {
      throw new Error('API token does not exist')
    }

    if (!user?.defaultOrg) {
      throw new Error('OrganizationId does not exist')
    }

    if (!user?.defaultWorkspaceId) {
      throw new Error('WorkspaceId does not exist')
    }

    const queryWithoutWhere = { ...query, where: undefined }
    const queryString = buildQueryString(queryWithoutWhere, user)
    const queryParser = QueryParser(query)
    const payload = {
      ...(query?.where ? { query: queryParser.parseWhere() } : {})
    }

    const response = await fetch(`${CONFIG.BACKEND_BASE_URL}/${serviceName}/find?${queryString}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(payload),
      ...(options.signal ? { signal: options.signal } : {})
    })

    const result = await response.json()

    if (!response.ok) {
      throw new Error(result.message)
    }

    return result
  }

  const APIGetOne = async (serviceName, id, query, alternativeToken = null, resourcePath = '') => {
    const user = getUserRefData()
    const token = alternativeToken || (await getAuth0Token())
    let defaultUrl = `${CONFIG.BACKEND_BASE_URL}/${serviceName}${resourcePath}/${id}`
    let auditlogsUrl = `${CONFIG.BACKEND_BASE_URL}/auditlogs/${serviceName}${resourcePath}/${id}`

    let url = isAuditLogsActive ? auditlogsUrl : defaultUrl

    if (!user?.defaultOrg) {
      throw new Error('OrganizationId does not exist')
    }

    if (!user?.defaultWorkspaceId) {
      throw new Error('WorkspaceId does not exist')
    }

    if (query) {
      const queryString = buildQueryString(query, user)
      url = (isAuditLogsActive ? auditlogsUrl : url).concat(`?${queryString}`)
    }

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })

    if (response.status === 204) {
      return null
    }

    const result = await response.json()

    if (!response.ok) {
      throw new HttpError(result.message, response.status)
    }

    if (result.statusCode === 403) {
      throw new HttpError(result.message, 403)
    }

    return result
  }

  const APIDelete = async (serviceName, id, alternativeToken = null, resourcePath = '') => {
    const token = alternativeToken || (await getAuth0Token())

    const response = await fetch(`${CONFIG.BACKEND_BASE_URL}/${serviceName}${resourcePath}/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    const result = await response.json()

    if (!response.ok) {
      throw new Error(result.message)
    }

    return result
  }

  const APICreate = async (serviceName, payload, alternativeToken = null, resourcePath = '') => {
    const user = getUserRefData()
    const token = alternativeToken || (await getAuth0Token())
    // Add organizationId and workspaceId to payload
    payload.organizationId = user.defaultOrg
    payload.workspaceId = user.defaultWorkspaceId
    const response = await fetch(
      `${CONFIG.BACKEND_BASE_URL}/${serviceName}${resourcePath}?workspaceId=${user.defaultWorkspaceId}&organizationId=${user.defaultOrg}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(payload)
      }
    )
    const result = await response.json()
    if (!response.ok) {
      throw new Error(result.message)
    }
    return result
  }

  const APIUpdate = async (serviceName, id, payload, alternativeToken = null, resourcePath = '') => {
    const user = await getUserRefData()
    const token = alternativeToken || (await getAuth0Token())
    const sanitizedPayload = sanitizeObject(payload)

    const body = {
      ...sanitizedPayload,
      lastModifiedBy: {
        userId: user.uid,
        displayName: `${user.firstName} ${user.lastName}`
      }
    }

    const response = await fetch(`${CONFIG.BACKEND_BASE_URL}/${serviceName}${resourcePath}/${id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(body)
    })
    const result = await response.json()

    if (!response.ok) {
      throw new Error(result.message)
    }

    return result
  }

  const actionMap = {
    [API_METHODS.GET_ALL]: APIGetAll,
    [API_METHODS.GET_ALL_WITH_PAYLOAD]: APIGetAllWithPayload,
    [API_METHODS.GET_ONE]: APIGetOne,
    [API_METHODS.CREATE]: APICreate,
    [API_METHODS.DELETE]: APIDelete,
    [API_METHODS.UPDATE]: APIUpdate
  }

  const action = async (...arguments_) => {
    try {
      if ([API_METHODS.GET_ALL, API_METHODS.GET_ONE].includes(method)) {
        setResult(null)
      }
      setError(null)
      const asyncFunction = actionMap[method]
      if (!asyncFunction) {
        throw new Error('Invalid method')
      }
      setIsLoading(true)
      const result = await asyncFunction(hookServiceName, ...arguments_, token, resourcePath)
      if ([API_METHODS.GET_ALL, API_METHODS.GET_ALL_WITH_PAYLOAD, API_METHODS.GET_ONE].includes(method)) {
        setResult(result)
        if (method === API_METHODS.GET_ONE) {
          originalResultReference.current = JSON.stringify(result)
        }
      } else {
        setResult(null)
      }
      return result
    } catch (error) {
      await errorCollector.collectErrors(error, { method, service: hookServiceName })
      setError(error.message)
      throw error
    } finally {
      setIsLoading(false)
    }
  }

  const appendElementToList = (element) => {
    if (method !== API_METHODS.GET_ALL) {
      throw new Error('INVALID_METHOD')
    }
    const currentResult = resultReference.current || []
    const newResult = [...currentResult, element]
    setResult(newResult)
  }

  const prependElementToList = (element) => {
    if (method !== API_METHODS.GET_ALL) {
      throw new Error('INVALID_METHOD')
    }
    const currentResult = resultReference.current || []
    const newResult = [element, ...currentResult]
    setResult(newResult)
  }

  const removeElementFromList = (element) => {
    if (method !== API_METHODS.GET_ALL) {
      throw new Error('INVALID_METHOD')
    }
    const currentResult = resultReference.current || []
    const newResult = currentResult.filter((item) => item._id !== element._id)
    setResult(newResult)
  }

  const updateElementInList = (elementKey, updatedValues) => {
    if (method !== API_METHODS.GET_ALL) {
      throw new Error('INVALID_METHOD')
    }

    const currentResult = resultReference.current || []
    const newResult = currentResult.map((item, key) => {
      if (key !== elementKey) {
        return item
      }
      return {
        ...item,
        ...updatedValues
      }
    })
    setResult(newResult)
  }

  const updateElementInListById = (elementId, updatedValues) => {
    if (method !== API_METHODS.GET_ALL) {
      throw new Error('INVALID_METHOD')
    }
    const currentResult = resultReference.current || []
    const newResult = currentResult.map((item) => {
      if (item._id !== elementId) {
        return item
      }
      return {
        ...item,
        ...updatedValues
      }
    })
    setResult(newResult)
  }

  const updateElement = (updatedValues) => {
    if (method !== API_METHODS.GET_ONE) {
      throw new Error('INVALID_METHOD')
    }
    const currentResult = resultReference.current || []
    const newResult = {
      ...currentResult,
      ...updatedValues
    }
    setResult(newResult)
  }

  const replaceData = (value) => {
    if (method !== API_METHODS.GET_ONE) {
      throw new Error('INVALID_METHOD')
    }
    originalResultReference.current = JSON.stringify(value)
    setResult(value)
  }

  const updateValueInObject = (objectKey, value) => {
    if (![API_METHODS.GET_ONE, API_METHODS.CREATE].includes(method)) {
      throw new Error('INVALID_METHOD')
    }
    const currentResult = resultReference.current || {}
    const newResult = {
      ...currentResult,
      [objectKey]: value
    }
    setResult(newResult)
  }

  const getElementById = async (id) => {
    if (method !== API_METHODS.GET_ALL) {
      throw new Error('INVALID_METHOD')
    }
    const currentResult = resultReference.current
    const elementInCurrentResult = currentResult?.find((item) => item._id === id)
    if (elementInCurrentResult) {
      return elementInCurrentResult
    }
    return await APIGetOne(hookServiceName, id)
  }

  /**
   * Fetches all records by organization ID.
   *
   * @param {string|number} orgId - The organization ID to filter by.
   * @param {string} [field='orgId'] - The field name to filter by orgId.
   * @param {number} [limit=25] - The maximum number of records to retrieve.
   * @param {Array<Object>} [where=[]] - Additional filter conditions.
   * @param {Array<string>} [projection] - The fields to be returned in the result.
   *
   * @returns {Promise<Object>} The result of the fetch operation.
   *
   * @throws {Error} Throws an error if the method is not 'GET_ALL' or if the orgId is not provided.
   */
  const getAllByOrgId = async ({
    orgId,
    field = 'orgId',
    skip = 0,
    limit = 25,
    where = [],
    projection,
    isProjectionExcluding,
    orderBy = 'updatedAt',
    disableCache
  } = {}) => {
    if (method !== API_METHODS.GET_ALL) {
      throw new Error('INVALID_METHOD')
    }

    if (!orgId) {
      throw new Error('INVALID_ORG_ID')
    }

    const query = {
      where: [
        {
          field: field,
          op: 'equal',
          val: orgId
        },
        ...where
      ],
      orderBy: { field: orderBy, sort: 'desc' },
      limit: limit,
      skip,
      projection,
      isProjectionExcluding,
      disableCache
    }

    return actionOverride ? await actionOverride(query) : await action(query)
  }

  const getResult = () => {
    return resultReference.current
  }

  const compareChanges = async () => {
    let result = {}

    const JSONParsed = JSON.parse(originalResultReference.current)

    for (const key of Object.keys(resultReference.current || {})) {
      if (JSON.stringify(resultReference.current[key]) !== JSON.stringify(JSONParsed[key])) {
        result[key] = resultReference.current[key]
      }
    }

    if (Object.keys(result).length === 0) {
      return { changes: null }
    }

    const hash = await generateSHA256Hash(JSON.stringify(result))

    return { changes: result, hash }
  }

  /**
   * `payloadTransformer`: Function that customizes the payload for API calls.
   * `apiTransportAdapter`: Function that customizes the transport layer for API calls.
   * If provided, this function replaces the default HTTP transport mechanism with an alternative,
   * such as WebSocket or another protocol, to suit specific communication needs.
   * This allows for flexible API interaction, adapting to different network conditions or requirements.
   */
  const compareChangesAndUpdate = async (payloadTransformer = null, apiTransportAdapter = null) => {
    if (isUpdatingReference.current) {
      return
    }
    try {
      const { changes, hash } = await compareChanges()
      if (changes && hash !== activeUpdateHash.current) {
        activeUpdateHash.current = hash
        isUpdatingReference.current = true
        setIsSavingDocument(true)
        let payload = changes
        if (payloadTransformer) {
          payload = payloadTransformer(changes)
        }

        await (apiTransportAdapter
          ? apiTransportAdapter({
              service: hookServiceName,
              id: resultReference.current._id,
              payload
            })
          : APIUpdate(hookServiceName, resultReference.current._id, payload))

        originalResultReference.current = JSON.stringify(resultReference.current)
      }
    } catch (error) {
      logger.error(error)
      alert?.openAlert({
        type: 'error',
        title: 'Saving Error!',
        message: 'Unable to save changes due to an error.'
      })
    } finally {
      isUpdatingReference.current = false
      setIsSavingDocument(false)
    }
  }

  const resetResult = () => {
    setResult(null)
    setError(null)
  }

  const utils = {
    setResult,
    clearResult,
    appendElementToList,
    prependElementToList,
    removeElementFromList,
    updateElementInList,
    updateElementInListById,
    updateElement,
    updateValueInObject,
    getElementById,
    getAllByOrgId,
    getResult,
    compareChangesAndUpdate,
    compareChanges,
    isSavingDocument,
    resetResult,
    originalResultRef: originalResultReference,
    isUpdatingRef: isUpdatingReference,
    activeUpdateHash,
    setError,
    replaceData
  }

  return [action, isLoading, error, result, utils]
}

export default useFetchAPI
