import { useRef, useState } from 'react'
import { API_METHODS, API_SERVICES } from '../../utils/constants'
import useFetchAPI from '../useFetchAPI'
import PayloadNormalizer from '../../utils/payloadNormalizer'

const useIssue = () => {
  const [getAllIssue, isLoadingAllIssue, errorGettingAllIssue, allIssue, allIssueUtils] = useFetchAPI(
    API_METHODS.GET_ALL,
    API_SERVICES.ISSUE
  )
  const [getAllIssueWithPayload] = useFetchAPI(API_METHODS.GET_ALL_WITH_PAYLOAD, API_SERVICES.ISSUE)
  const [getOneIssue, isLoadingOneIssue, errorGettingOneIssue, oneIssue, oneIssueUtils] = useFetchAPI(
    API_METHODS.GET_ONE,
    API_SERVICES.ISSUE
  )
  const [createIssueAPI, isCreatingIssue, errorCreatingIssue, createIssueState, createIssueUtils] = useFetchAPI(
    API_METHODS.CREATE,
    API_SERVICES.ISSUE
  )
  const [removeIssue, isRemovingIssue, errorRemovingIssue] = useFetchAPI(API_METHODS.DELETE, API_SERVICES.ISSUE)
  const [updateIssueAPI, isUpdatingIssue, errorUpdatingIssue] = useFetchAPI(API_METHODS.UPDATE, API_SERVICES.ISSUE)

  const [issueUpdateStatus, setIssueUpdateStatus] = useState(false)

  const [temporaryIssue, setTemporaryIssue] = useState()

  const payloadNormalizer = new PayloadNormalizer()

  const createIssue = (payload, ...restArgument) => {
    const payloadNormalized = payloadNormalizer.issue(payload)
    return createIssueAPI(payloadNormalized, ...restArgument)
  }

  const updateIssue = (id, payload, ...restArgument) => {
    const payloadNormalized = payloadNormalizer.issue(payload)
    return updateIssueAPI(id, payloadNormalized, ...restArgument)
  }

  const [issueListWithDetails, changeIssueListWithDetails] = useState([])
  const issueListWithDetailsReference = useRef([])

  const setIssueListWithDetails = (issues) => {
    changeIssueListWithDetails(issues)
    issueListWithDetailsReference.current = issues
  }

  return {
    getAll: {
      action: getAllIssue,
      isLoading: isLoadingAllIssue,
      error: errorGettingAllIssue,
      result: allIssue || [],
      utils: allIssueUtils
    },
    getAllWithPayload: {
      action: getAllIssueWithPayload
    },
    getOne: {
      action: getOneIssue,
      isLoading: isLoadingOneIssue,
      error: errorGettingOneIssue,
      result: oneIssue,
      utils: oneIssueUtils
    },
    create: {
      action: createIssue,
      isLoading: isCreatingIssue,
      error: errorCreatingIssue,
      result: createIssueState,
      utils: createIssueUtils
    },
    remove: {
      action: removeIssue,
      isLoading: isRemovingIssue,
      error: errorRemovingIssue
    },
    update: {
      action: updateIssue,
      isLoading: isUpdatingIssue,
      error: errorUpdatingIssue
    },
    tempIssue: temporaryIssue,
    setTempIssue: setTemporaryIssue,
    issueUpdateStatus,
    setIssueUpdateStatus,
    issueList: {
      ref: issueListWithDetailsReference,
      result: issueListWithDetails,
      set: setIssueListWithDetails
    }
  }
}

export default useIssue
