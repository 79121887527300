/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable max-lines-per-function */
import { Fragment, useContext, useEffect, useRef } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { Dialog, Transition } from '@headlessui/react'
import { ArrowSmallRightIcon } from '@heroicons/react/24/outline'
import { useIntercom } from 'react-use-intercom'
import { withAuthenticationRequired } from '@auth0/auth0-react'

import { INTERCOM_APP_ID } from '../../utils/constants'
import classNames from '../../utils/text/classnames'
import { useAuth } from '../../context/AuthProvider'
import LoadingIcon from '../icons/LoadingIcon'
import AppHeader from '../ui/header/AppHeader'
import TopBannerNotification from '../ui/notification/TopBannerNotification'
import FullScreenPageLoader from '../helpers/FullScreenPageLoader'
import useDashboardSidebarLayout from '../../hooks/useDashboardSidebarLayout'
import { WorkspaceContext } from '../../context/WorkspaceProvider'
import WorkspaceSidebar from './WorkspaceSidebar'
import SidebarMenu from './SidebarMenu'

const userNavigation = [{ name: 'Log out', href: '/logout' }]

function DashboardSidebarLayout() {
  const { user, permissions, sendVerificationEmail } = useAuth()
  const {
    sidebarOpen,
    sidebarCollapsed,
    hideOnFullScreen,
    workspacePanelOpen,
    hideHeader,
    sendingEmail,
    title,
    actionBtn,
    setSidebarOpen,
    setSidebarCollapsed,
    setHideOnFullScreen,
    setWorkspacePanelOpen,
    setHideHeader,
    setTitle,
    setActionBtn,
    handleResendEmail,
    showMapNavigation,
    setShowMapNavigation
  } = useDashboardSidebarLayout({ sendVerificationEmail })

  const workspace = useContext(WorkspaceContext)

  const workspaceButtonReference = useRef()

  const { boot, show, hide, isOpen } = useIntercom()

  const handleIntercomClick = () => {
    boot({
      app_id: INTERCOM_APP_ID,
      name: user.displayName,
      email: user.email,
      custom_launcher_selector: '#intercom_button',
      hideDefaultLauncher: true,
      alignment: 'left'
    })
    if (isOpen === false) {
      show()
    } else {
      hide()
    }
  }

  useEffect(() => {
    document.body.classList.remove('bg-gray-200')
    document.body.classList.add('bg-white')
    document.body.classList.add('h-full')
  })

  if (!user) {
    return <FullScreenPageLoader />
  }

  if (!user?.onboarded) {
    return <Navigate to="/account/setup" />
  }

  if (!user?.orgIds?.length) {
    return <Navigate to="/account/organization" />
  }

  /* istanbul ignore next */
  const showSidebar = () => {
    setSidebarCollapsed(false)
  }

  return (
    <div className="h-full min-h-full">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative z-50 flex flex-col flex-1 w-full max-w-xs pt-5 pb-4 bg-white">
                <SidebarMenu
                  user={user}
                  permissions={permissions}
                  userNavigation={userNavigation}
                  workspaceButtonReference={workspaceButtonReference}
                  workspace={workspace}
                  showSidebar={showSidebar}
                  isOpen={isOpen}
                  handleIntercomClick={handleIntercomClick}
                  sidebarCollapsed={sidebarCollapsed}
                  setSidebarCollapsed={setSidebarCollapsed}
                  setSidebarOpen={setSidebarOpen}
                  setWorkspacePanelOpen={setWorkspacePanelOpen}
                />
              </Dialog.Panel>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {user?.emailVerified === false && (
        <TopBannerNotification variant="info">
          <p>
            It looks like your account hasn't been verified yet. Please check your email.
            <button
              className="inline-flex items-center ml-2 font-bold underline underline-offset-2"
              onClick={handleResendEmail}
            >
              Resend link{' '}
              {sendingEmail ? (
                <LoadingIcon data-testid="loading-btn" />
              ) : (
                <ArrowSmallRightIcon className="w-5 h-5 ml-1" />
              )}
            </button>
          </p>
        </TopBannerNotification>
      )}

      {user?.workspaces && !!user?.workspaces?.length && (
        <WorkspaceSidebar
          isOpen={workspacePanelOpen}
          onClose={() => setWorkspacePanelOpen(false)}
          buttonRef={workspaceButtonReference}
          hideOnFullScreen={hideOnFullScreen}
          sidebarCollapsed={sidebarCollapsed}
          user={user}
        />
      )}

      <div
        className={classNames(
          'transition-all z-12 duration-200 lg:inset-y-0 lg:pt-5 lg:pb-4 border-r lg:bg-gray-50 max-lg:hidden',
          !hideOnFullScreen && (sidebarCollapsed ? 'lg:w-20' : 'lg:w-[280px]'),
          hideOnFullScreen ? 'hidden' : 'lg:flex lg:flex-col lg:fixed',
          [!user.emailVerified && 'notyet-verified mt-12']
        )}
      >
        <SidebarMenu
          user={user}
          permissions={permissions}
          userNavigation={userNavigation}
          workspaceButtonReference={workspaceButtonReference}
          workspace={workspace}
          showSidebar={showSidebar}
          isOpen={isOpen}
          handleIntercomClick={handleIntercomClick}
          sidebarCollapsed={sidebarCollapsed}
          setSidebarCollapsed={setSidebarCollapsed}
          setSidebarOpen={setSidebarOpen}
          setWorkspacePanelOpen={setWorkspacePanelOpen}
        />
      </div>

      {/* Main column */}
      <div
        className={classNames(
          'flex flex-col h-full transition-all duration-200',
          !hideOnFullScreen && (sidebarCollapsed ? 'lg:pl-20' : 'lg:pl-[280px]')
        )}
      >
        <main className={classNames('flex flex-col flex-1', user?.emailVerified === false && 'pt-12')}>
          <AppHeader
            title={title}
            ActionButtonComponent={actionBtn}
            hideOnFullScreen={hideOnFullScreen}
            hideHeader={hideHeader}
            showMapNavigation={showMapNavigation}
            setSidebarOpen={setSidebarOpen}
          />
          <div className={classNames('flex-1 h-full px-4 sm:px-6 lg:px-8 mt-0')}>
            <Outlet
              context={{
                setTitle,
                setActionBtn,
                setSidebarCollapsed,
                setHideOnFullScreen,
                setHideHeader,
                setShowMapNavigation
              }}
            />
          </div>
        </main>
      </div>
    </div>
  )
}

/* istanbul ignore next */
export default withAuthenticationRequired(DashboardSidebarLayout, {
  onRedirecting: () => <FullScreenPageLoader />
})
