import { LockClosedIcon, LockOpenIcon } from '@heroicons/react/20/solid'

export const options = [
  {
    id: 1,
    value: 'organization',
    name: 'Organization',
    description: 'Everyone in {orgName} can view',
    tooltip: 'All members of {orgName} have access to this content.\nThe information here is visible to everyone.',
    icon: LockOpenIcon
  },
  {
    id: 2,
    value: 'restricted',
    name: 'Restricted',
    description: 'Only invite people can access',
    tooltip:
      'This curated content is restricted for invited individuals only, \nenhancing security by limiting access to sensitive information.',
    icon: LockClosedIcon
  }
]
