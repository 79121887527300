import { useLocalStorage } from './useLocalStorage'

// Hook for Kanban View Config
export function useKanbanViewConfig() {
  const [data, setData] = useLocalStorage('kanbanViewConfig', [])

  const saveBoards = (newBoard) => {
    setData((existingBoards) => [...existingBoards, newBoard])
  }

  const updateBoard = (id, value) => {
    setData(() =>
      data?.map((board) => {
        // istanbul ignore else
        if (board.id === id) {
          return value
        }

        // istanbul ignore next
        return board
      })
    )
  }

  const deleteBoard = (id) => {
    setData((previousData) => previousData?.filter((board) => board.id !== id))
  }

  return { boards: data, saveBoards, updateBoard, deleteBoard }
}
