import classNames from '../../../utils/text/classnames'

const bgClass = {
  info: 'bg-indigo-600',
  error: 'bg-red-500',
  success: 'bg-green-500'
}
export default function TopBannerNotification({ children, variant = 'success' }) {
  return (
    <div className={classNames(bgClass[variant], 'box-content fixed w-full z-40')} data-testid="top-bar-notification">
      <div className="container py-3 mx-auto text-center text-white">{children}</div>
    </div>
  )
}
