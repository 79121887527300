// istanbul ignore file

import { useAuth0 } from '@auth0/auth0-react'
import { API_METHODS, API_SERVICES, AUTH0_AUDIENCE, CONFIG } from '../../utils/constants'
import sanitizeObject from '../../utils/sanitizeObject'
import useFetchAPI from '../useFetchAPI'

const useOrganization = () => {
  const [
    getOneOrganization,
    isLoadingOneOrganization,
    errorGettingOneOrganization,
    oneOrganization,
    oneOrganizationUtils
  ] = useFetchAPI(API_METHODS.GET_ONE, API_SERVICES.ORGANIZATION)
  const { getAccessTokenSilently } = useAuth0()

  const createOrganization = async ({ name, size }) => {
    const token = await getAccessTokenSilently({
      authorizationParams: { audience: AUTH0_AUDIENCE }
    })
    if (!token) {
      throw new Error("Can't get token from auth0")
    }
    const data = {
      name,
      size
    }
    const sanitizedPayload = sanitizeObject(data)
    const response = await fetch(`${CONFIG.BACKEND_BASE_URL}/organizations?disableCache=true`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(sanitizedPayload)
    })

    if (response.status === 409) {
      throw new Error('Organization already exists')
    }

    const result = await response.json()

    if (!response.ok) {
      throw new Error(result.message)
    }

    return result
  }

  return {
    createOrganization,
    getOne: {
      action: getOneOrganization,
      isLoading: isLoadingOneOrganization,
      error: errorGettingOneOrganization,
      result: oneOrganization,
      utils: oneOrganizationUtils
    }
  }
}

export default useOrganization
