import { XCircleIcon, XMarkIcon, CheckCircleIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import { useState } from 'react'

const background = {
  success: 'bg-green-50',
  error: 'bg-red-50'
}

const colors = {
  success: 'text-green-400',
  error: 'text-red-800'
}

const iconColors = {
  success: 'text-green-500',
  error: 'text-red-500'
}

export default function Alert({ title, message, btnAction, variant = 'success', dismissable, className, onClose }) {
  const [dismissed, setDismissed] = useState(false)

  if (dismissed) return null

  return (
    <div
      className={classNames('p-4 rounded-md relative', background[variant] ?? '', colors[variant], className)}
      data-testid="alert"
    >
      <div className="flex">
        <div className="flex-shrink-0">
          {variant === 'success' && <CheckCircleIcon className="w-5 h-5 text-green-500" aria-hidden="true" />}
          {variant === 'error' && <XCircleIcon className="w-5 h-5 text-red-500" aria-hidden="true" />}
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium">{title}</h3>
          {message && (
            <div
              className={classNames('text-sm', {
                'mt-2': title
              })}
            >
              <p>{message}</p>
            </div>
          )}
          {btnAction}
        </div>
        {dismissable && (
          <div className="absolute top-4 right-4">
            <button
              type="button"
              className={classNames('inline-flex rounded-md', iconColors[variant])}
              onClick={() => {
                setDismissed(true)
                onClose?.()
              }}
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon className="w-5 h-5" aria-hidden="true" />
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
