import { useAuth } from '../context/AuthProvider'
import { API_METHODS, API_SERVICES } from '../utils/constants'
import useFetchAPI from './useFetchAPI'

const useInviteMemberModal = () => {
  const auth = useAuth()
  const uid = auth?.user?.uid
  const [getOneJourney, isLoadingOneJourney, errorGettingOneJourney, oneJourney, utils] = useFetchAPI(
    API_METHODS.GET_ONE,
    API_SERVICES.WORKSPACE
  )

  const memberList = (oneJourney?.members || []).filter((member) => member?.userId !== uid)

  const changeRole = (userId, role) => {
    const members = memberList?.map((member) => {
      if (member.userId === userId) {
        return {
          ...member,
          role
        }
      }
      return member
    })

    utils.updateValueInObject('members', members)
  }

  const addMembers = (invitedUsers, role) => {
    const members = [
      ...memberList,
      ...invitedUsers.map(({ email, userId }) => ({
        email,
        isPending: true,
        role,
        userId
      }))
    ]

    utils.updateValueInObject('members', members)
  }

  return {
    isLoading: isLoadingOneJourney,
    members: memberList,
    error: errorGettingOneJourney,
    changeRole,
    addMembers,
    getOneJourney
  }
}

export default useInviteMemberModal
