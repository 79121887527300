import { useForm } from 'react-hook-form'
import Button from '../../ui/button/Button'
import ControlledTextField from '../../ui/form/ControlledTextField'
import VisibilitySettings from '../VisibilitySettings'
import { options } from '../../../data/visibilitySettings'
import { useAuth } from '../../../context/AuthProvider'

const CreateWorkspace = ({
  onFormSubmit,
  showVisibilityComponent,
  isFirstStepRequired,
  isFormLoading,
  onWorkspaceModalClose
}) => {
  const auth = useAuth()

  const fields = {
    defaultValue: {
      name: '',
      visibility: options[0].value
    },
    rules: {
      name: { required: true },
      visibility: { required: false }
    }
  }

  const { handleSubmit, control, watch } = useForm({
    defaultValues: fields.defaultValue
  })

  const watchName = watch('name')
  const watchVisibility = watch('visibility')
  const buttonDisabled = !watchName || !watchVisibility
  const onSubmit = async (data) => {
    onFormSubmit(data)
  }

  return (
    <>
      <ControlledTextField
        control={control}
        id="name"
        rules={fields.rules.name}
        placeholder="Type your workspace name here"
        label="Workspace name"
        labelSize="text-sm"
      />
      {showVisibilityComponent && (
        <VisibilitySettings
          orgName={auth.user.defaultOrgName}
          control={control}
          id="visibility"
          rules={fields.rules.visibility}
        />
      )}

      <div className="!mt-[60px] flex justify-between items-center">
        <span className="text-gray-500">Step 1 of 2</span>
        <div className="flex items-center space-x-4">
          {!isFirstStepRequired && (
            <Button
              onClick={() => onWorkspaceModalClose()}
              color="white"
              variant="outline"
              customSize="py-2 rounded-md px-8"
              className="flex items-center space-x-2 text-sm font-medium text-gray-700"
            >
              Cancel
            </Button>
          )}
          <Button
            onClick={handleSubmit(onSubmit)}
            color="coolGray"
            customSize="py-2 rounded-md px-4"
            className="flex items-center space-x-2 text-sm"
            disabled={buttonDisabled}
          >
            <span>{isFormLoading ? 'Loading...' : 'Create Workspace'}</span>
          </Button>
        </div>
      </div>
    </>
  )
}

export default CreateWorkspace
