// istanbul ignore file
import { useAuth } from '../../context/AuthProvider'

/**
 * Parses an array of permissions into a more digestible object format.
 *
 * @param {string[]} permissions - An array of permissions in the format "domain:id:resource:action".
 * @returns {Object} A structured representation of permissions.
 */
export const parsePermissions = (permissions) => {
  return permissions.reduce((accumulator, permission) => {
    const [, , resource, action] = permission.split(':')
    if (!accumulator[resource]) {
      accumulator[resource] = {}
    }
    accumulator[resource][action] = true
    return accumulator
  }, {})
}

/**
 * Creates a permission checker object based on the given user permissions.
 *
 * @param {Object} userPermissions - Parsed user permissions.
 * @returns {Object} An object that contains methods to add permissions, set logic and evaluate.
 */
export const check = (userPermissions) => {
  let requiredPermissions = []
  let logic = 'AND'

  /**
   * Evaluates if the user has the required permissions.
   *
   * @returns {boolean} True if user has the permissions, otherwise false.
   */
  const evaluate = () => {
    if (logic === 'AND') {
      return requiredPermissions.every((perm) => {
        const [resource, action] = perm.split(':')
        return userPermissions?.[resource]?.[action]
      })
    } else if (logic === 'OR') {
      return requiredPermissions.some((perm) => {
        const [resource, action] = perm.split(':')
        return userPermissions?.[resource]?.[action]
      })
    }

    return false
  }

  const addPermission = (permission) => {
    requiredPermissions = [...requiredPermissions, ...(Array.isArray(permission) ? permission : [permission])]
    return { and: setAnd, or: setOr, evaluate }
  }

  const setAnd = (permission) => {
    logic = 'AND'
    return addPermission(permission)
  }

  const setOr = (permission) => {
    logic = 'OR'
    return addPermission(permission)
  }

  return { for: addPermission }
}

/**
 * Component to conditionally render content based on user permissions.
 *
 * @param {Object} props - Component props.
 * @param {string|string[]} props.I - The primary permission(s) to check.
 * @param {string|string[]} props.and - Additional permission(s) to check with an AND logic.
 * @param {string|string[]} props.or - Additional permission(s) to check with an OR logic.
 * @param {Function} props.yes - Render callback when user has the required permissions.
 * @param {Function} props.no - Render callback when user lacks the required permissions.
 * @returns {React.Element} Rendered element based on the permissions check.
 */
export const Can = ({ I, and, or, yes, no }) => {
  const { permissions: userPermissions } = useAuth()

  let permissionChecker = check(userPermissions).for(I)
  if (and) permissionChecker = permissionChecker.and(and)
  if (or) permissionChecker = permissionChecker.or(or)

  const hasPermission = permissionChecker.evaluate()
  return hasPermission ? yes() : no()
}

Can.defaultProps = {
  yes: () => null,
  no: () => null
}
