const ErrorMesssage = ({ errorMessage }) => {
  return (
    <div className="">
      <div
        className="bg-red-100 text-sm border border-red-400 text-red-700 px-4 py-3 rounded relative mt-6"
        role="alert"
      >
        <span className="block sm:inline">{errorMessage}</span>
      </div>
    </div>
  )
}

export default ErrorMesssage
