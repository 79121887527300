import classNames from '../../utils/text/classnames'

import TrinitiLogo from '../../assets/images/triniti-logo-text-black.png'
import TrinitiLogoPurple from '../../assets/images/triniti-logo-text-purple.png'

export default function Logo({ classes = 'h-7 w-auto', className, variant = 'white-purple' }) {
  return (
    <img
      className={classNames(classes, className, 'brightness-100')}
      src={variant === 'white-purple' ? TrinitiLogo : TrinitiLogoPurple}
      alt="Triniti Logo"
    />
  )
}
