/* eslint-disable react/react-in-jsx-scope */
import { Tooltip as ReactTooltip } from 'react-tooltip'
import classNames from '../../../utils/text/classnames'

const DEFAULT_OFFSET = 7

export default function TooltipComposer({
  selector = '.tooltip-default',
  offset = DEFAULT_OFFSET,
  place = 'top',
  customStyle,
  delay = 0,
  noArrow,
  width,
  zIndex,
  maxWidth,
  customClass,
  render,
  positionStrategy = 'absolute'
}) {
  return (
    <ReactTooltip
      anchorSelect={selector}
      place={place}
      offset={offset}
      className={classNames(customClass || '!rounded-xl !bg-background-500')}
      style={
        customStyle || {
          fontSize: '13px',
          padding: '8px',
          width: width,
          whiteSpace: 'break-spaces',
          zIndex: zIndex,
          maxWidth: maxWidth
        }
      }
      positionStrategy={positionStrategy}
      noArrow={noArrow}
      delayShow={delay}
      render={render}
    />
  )
}
