const irregularPlurals = {
  opportunity: 'opportunities',
  Opportunity: 'Opportunities'
  // add any other irregular plural forms here
}

export const pluralize = (count, noun, showCount = true, suffix = 's') => {
  if (count <= 1) {
    return `${showCount ? `${count} ` : ''}${noun}`
  } else {
    const pluralForm = irregularPlurals[noun] ? irregularPlurals[noun] : noun + suffix
    return `${showCount ? `${count} ` : ''}${pluralForm}`
  }
}
