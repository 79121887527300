// istanbul ignore file
import React, { useState } from 'react'

import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { options as settings } from '../../data/visibilitySettings'
import ComboBoxButton from '../ui/form/ComboBoxButton'
import TooltipComposer from '../ui/tooltip/TooltipComposer'
import VisibilityOption from './VisibilityOption'

export default function VisibilitySettingsDropdown({ orgName }) {
  const [selected, setSelected] = useState(settings[0])
  const replaceOrgName = (string_) => string_.replace('{orgName}', orgName)

  const handleOptionChange = (value) => {
    setSelected(value)
  }

  return (
    <div>
      <ComboBoxButton
        options={settings}
        searchable={false}
        onChange={handleOptionChange}
        selected={selected}
        color="white"
        className="flex justify-between px-0 items-center !font-normal shadow-none hover:bg-white focus:!ring-0"
        optionClassName="!p-0"
        portal={false}
        customOption={(setting) => (
          <VisibilityOption
            option={setting}
            orgName={orgName}
            hideIcon={true}
            defaultChecked={selected.id === setting.id}
          />
        )}
      >
        <div className="flex items-center">
          <selected.icon className="w-5 h-5 mr-4 text-gray-500" />
          <span>{selected.name}</span>
          <span className="leading-none whitespace-pre">{` - ${replaceOrgName(selected.description)}`}</span>
        </div>
        <ChevronDownIcon className="w-5 h-5 ml-4 text-gray-800" />
      </ComboBoxButton>
      <TooltipComposer selector=".radio-tooltip" delay={100} offset={7} place="bottom" zIndex={10_000} />
    </div>
  )
}
