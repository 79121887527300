import React from 'react'

const SuccessMessage = ({ successMessage }) => {
  return (
    <div data-testid="success-message">
      <div
        className="bg-green-100 text-sm border border-green-400 text-green-700 px-4 py-3 rounded relative mt-6"
        role="alert"
      >
        <span className="block sm:inline">{successMessage}</span>
      </div>
    </div>
  )
}

export default SuccessMessage
