import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid'
import { useController } from 'react-hook-form'
import { options } from '../../data/visibilitySettings'
import TooltipComposer from '../ui/tooltip/TooltipComposer'
import VisibilityOption from './VisibilityOption'

export default function VisibilitySettings({ id, control, rules, hideTitleIcon, orgName }) {
  const { field } = useController({
    name: id,
    control,
    rules
  })

  const handleOptionSelect = (index) => {
    const option = options[index]
    field.onChange(option.value)
  }

  return (
    <div className="flex flex-col space-y-4" data-testid="visibility-options">
      <span className="block">
        Visibility Settings{' '}
        {!hideTitleIcon && <QuestionMarkCircleIcon className="inline-block w-5 h-5 ml-4 text-gray-400" />}
      </span>

      {options.map((option, index) => (
        <VisibilityOption
          key={index}
          data-testid="visibility-option"
          option={option}
          checked={field.value === option.value}
          orgName={orgName}
          onChange={() => handleOptionSelect(index)}
        />
      ))}

      <TooltipComposer selector=".radio-tooltip" delay={100} offset={3} place="bottom" />
    </div>
  )
}
