import {
  PresentationChartBarIcon as PresentationChartBarIconOutline,
  MapIcon as MapIconOutline,
  UsersIcon as UsersIconOutline,
  QuestionMarkCircleIcon as QuestionMarkCircleIconOutline,
  ViewColumnsIcon as ViewColumnsIconOutline,
  QueueListIcon as QueueListIconOutline
} from '@heroicons/react/24/outline'
import {
  PresentationChartBarIcon,
  MapIcon,
  UsersIcon,
  QuestionMarkCircleIcon,
  ViewColumnsIcon,
  PlusIcon,
  QueueListIcon
} from '@heroicons/react/24/solid'
import t from './data/i18n'

export const navigations = [
  {
    name: 'Dashboard',
    href: 'home',
    icon: PresentationChartBarIcon,
    outlineIcon: PresentationChartBarIconOutline,
    current: true
  },
  {
    name: t('map.texts.titlePlural'),
    href: 'maps',
    icon: MapIcon,
    outlineIcon: MapIconOutline,
    current: false,
    matchNestedPath: true
  },
  {
    name: 'List View',
    href: 'tasks',
    icon: QueueListIcon,
    outlineIcon: QueueListIconOutline,
    current: false
  },
  {
    name: 'Board View',
    href: 'tasks/board',
    icon: ViewColumnsIcon,
    outlineIcon: ViewColumnsIconOutline,
    current: false,
    isBoardView: true
  },
  { name: t('people.texts.title'), href: 'personas', icon: UsersIcon, outlineIcon: UsersIconOutline, current: false },
  {
    name: 'Help & Support',
    icon: QuestionMarkCircleIcon,
    outlineIcon: QuestionMarkCircleIconOutline,
    current: false,
    isIntercom: true
  }
]

export const otherNavigations = [
  {
    name: 'Create a new board',
    href: 'tasks/new/board',
    icon: ViewColumnsIconOutline,
    current: true
  }
]
