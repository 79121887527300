import { useController } from 'react-hook-form'
import TextField from './TextField'

const ControlledTextField = ({ id, control, rules, ...properties }) => {
  const { field } = useController({
    name: id,
    control,
    rules
  })

  const value = field.value ?? ''

  return <TextField id={id} value={value} onChange={field.onChange} {...properties} />
}

export default ControlledTextField
