import { BellIcon } from '@heroicons/react/24/outline'
import { PopoverNotificationCenter } from '@novu/notification-center'

const NotificationBell = () => {
  return (
    <PopoverNotificationCenter colorScheme="light">
      {({ unseenCount }) => {
        return (
          <button
            className="relative hover:bg-gray-100 text-gray-500 font-bold p-2 rounded-full inline-flex items-center"
            data-testid="notification-bell"
          >
            <BellIcon className="inline w-7 h-7" />
            {!!unseenCount && (
              <span className="absolute top-0 right-0 px-2 py-1 text-xs font-bold leading-none text-red-100 transform bg-red-500 rounded-full">
                {unseenCount}
              </span>
            )}
          </button>
        )
      }}
    </PopoverNotificationCenter>
  )
}

export default NotificationBell
