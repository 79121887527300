import { useState } from 'react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import AvatarBackgroundImage from '../ui/avatar/AvatarBackgroundImage'
import Badge from '../ui/badge/Badge'
import ComboBoxButton from '../ui/form/ComboBoxButton'
import FallbackMemberAvatar from '../../assets/images/member-avatar.png'
import { RoleTypes } from '../../utils/constants'

const MemberItem = ({ email, picture, isPending, isLoading, roleName, handleMemberTypeChange, name }) => {
  const [defaultName, setDefaultName] = useState({
    type: isPending ? 'email' : 'fullName',
    value: isPending ? email : name
  })

  const toggleName = () => {
    if (isPending) {
      return
    }
    setDefaultName((defaultName) => {
      if (defaultName.type === 'email') {
        return { type: 'fullName', value: name }
      }
      return { type: 'email', value: email }
    })
  }

  const roleTypesWithDeleteOption = [
    ...RoleTypes,
    {
      id: 'remove',
      name: 'Remove',
      separator: true
    }
  ]

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center space-x-2">
        <div className="w-9">
          <AvatarBackgroundImage size="full" imageUrl={picture} customFallback={FallbackMemberAvatar} />
        </div>
        <span onClick={toggleName} className="text-sm font-normal text-gray-800" data-testid="member-name">
          {defaultName.value}
        </span>
        {isPending && (
          <Badge size="md" color="yellow">
            Pending
          </Badge>
        )}
      </div>
      <div>
        <ComboBoxButton
          options={roleTypesWithDeleteOption}
          onChange={handleMemberTypeChange}
          searchable={false}
          placement="left-end"
          optionClassName="!p-0"
          className="!font-normal capitalize text-gray-800"
          customColor="bg-white hover:bg-gray-100 shadow-none"
          disabled={isLoading}
        >
          {roleName}
          <ChevronDownIcon className="inline-block w-5 h-5 ml-2 text-gray-800" />
        </ComboBoxButton>
      </div>
    </div>
  )
}

export default MemberItem
