import RadioButton from '../ui/form/RadioButton'

export default function VisibilityOption({ option, orgName, checked, hideIcon = false, onChange }) {
  const replaceOrgName = (string_) => string_.replace('{orgName}', orgName)

  const IconComponent = option.icon
  const description = replaceOrgName(option.description)
  const tooltip = replaceOrgName(option.tooltip)

  return (
    <RadioButton
      key={option.id}
      id={option.value}
      checked={checked}
      label={
        <span className="flex items-center">
          <span className="radio-tooltip" data-tooltip-content={tooltip}>
            {option.name}
          </span>
          <span className="leading-none whitespace-pre">{` - ${description}`}</span>
          {!hideIcon && <IconComponent className="w-5 h-5 ml-4 text-gray-500" data-testid="option-icon" />}
        </span>
      }
      onChange={onChange}
    />
  )
}
