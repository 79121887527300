import { useEffect } from 'react'
import OnboardingHeader from '../onboarding/OnboardingHeader'
import Modal from '../ui/modal/Modal'
import useWorkspace from '../../hooks/dataFetching/useWorkspace'
import ErrorMesssage from '../helpers/ErrorMessage'
import { useAuth } from '../../context/AuthProvider'
import useWorkspaceModal from '../../pages/workspaces/useWorkspaceModal'
import CollaborateOnWorkspace from './forms/CollaborateOnWorkspace'
import CreateWorkspace from './forms/CreateWorkspace'

export default function WorkspaceModal({
  firstStepTitle,
  firstStepDescription,
  isFirstStepRequired,
  secondStepTitle,
  secondStepDescription,
  showVisibilityComponent = true,
  onWorkspaceModalClose,
  onCreateWorkspaceDone,
  isVisible,
  isCloseable = false,
  workspaceModalTabIndex
}) {
  const { user } = useAuth()

  const { createWorkspace, isLoading, errorMessage, inviteToWorkspace } = useWorkspace()

  const {
    workspaceName,
    isRestricted,
    activeStep,
    isSecondStepLoading,
    hasSentInvitation,
    onCreateWorkspaceSubmit,
    onCloseCollaborateModal,
    onCollaborateOnWorkspaceSubmit,
    setHasSentInvitation
  } = useWorkspaceModal({
    user,
    workspaceModalTabIndex,
    createWorkspace,
    onCreateWorkspaceDone,
    onWorkspaceModalClose,
    inviteToWorkspace
  })

  useEffect(() => {
    setHasSentInvitation(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible])

  return (
    <>
      <Modal
        data-testid="workspace-modal"
        open={isVisible && activeStep === 1}
        hideCloseButton={!isCloseable}
        onClose={isCloseable && onWorkspaceModalClose}
      >
        <Modal.Body>
          <OnboardingHeader hideLogo={true} title={firstStepTitle} description={firstStepDescription} />
          <div className="px-6 space-y-8">
            {errorMessage && <ErrorMesssage errorMessage={errorMessage} />}
            <CreateWorkspace
              isFirstStepRequired={isFirstStepRequired}
              onFormSubmit={onCreateWorkspaceSubmit}
              showVisibilityComponent={showVisibilityComponent}
              isFormLoading={isLoading}
              onWorkspaceModalClose={onWorkspaceModalClose}
            />
          </div>
        </Modal.Body>
      </Modal>
      <Modal open={isVisible && activeStep === 2} hideCloseButton={true}>
        <Modal.Body>
          <OnboardingHeader
            hideLogo={true}
            workspaceName={workspaceName}
            isRestricted={isRestricted}
            title={secondStepTitle}
            description={secondStepDescription}
          />
          <div className="px-6 space-y-8">
            <CollaborateOnWorkspace
              onFormSubmit={onCollaborateOnWorkspaceSubmit}
              isFormLoading={isSecondStepLoading}
              onFormClose={onCloseCollaborateModal}
              hidePreviousStep={!!workspaceModalTabIndex}
              cancelButtonLabel={hasSentInvitation ? 'Close' : workspaceModalTabIndex ? 'Cancel' : 'Do this later'}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
