/**
 * Utility methods used in built-in actions.
 *
 * @type {Object}
 */
export const QueryParser = (queryParameters) => {
  return {
    /**
     * @param {Object} queryParams.where
     */
    parseWhere: () => {
      const opMap = {
        equal: '$eq',
        notEqual: '$ne',
        greaterThan: '$gt',
        greaterThanOrEqual: '$gte',
        lessThan: '$lt',
        lessThanOrEqual: '$lte',
        in: '$in',
        notIn: '$nin',
        like: '$regex'
        // Add more operators as needed
      }

      let queryWhere = {}

      if (queryParameters?.where) {
        let value = queryParameters.where
        value = Array.isArray(value) ? value : [value]

        queryWhere = value.reduce((accumulator, item) => {
          let op = opMap[item.op]
          if (op === '$regex') {
            accumulator[item.field] = { [op]: `^${item.val}` } // Matches strings that start with item.val
          } else {
            accumulator[item.field] = { [op]: item.val }
          }
          return accumulator
        }, {})
      }

      return queryWhere
    },

    /**
     * @param {Number} queryParams.limit
     */
    parseLimit: () => {
      return queryParameters?.limit
    },

    /**
     * @param {Number} queryParams.skip
     */
    parseSkip: () => {
      return queryParameters?.skip
    },

    /**
     * @param {Object} queryParams.orderBy
     */
    parseSort: () => {
      if (!queryParameters?.orderBy?.field) return {}

      const sortOrder = queryParameters?.orderBy?.sort === 'desc' ? -1 : 1
      return { [queryParameters?.orderBy?.field]: sortOrder }
    },

    /**
     * @param {Object} queryParams.projection
     */

    parseProjection: (excluding = false) => {
      if (!queryParameters.projection) return null

      return queryParameters.projection.reduce((accumulator, field) => {
        accumulator[field] = excluding ? 0 : 1
        return accumulator
      }, {})
    }
  }
}
