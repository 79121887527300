import { useContext, createContext } from 'react'
import useErrorCollector from '../hooks/useErrorCollector'

const ErrorCollectorContext = createContext()

// This should wrap the app in `src/components/app.js`
export default function ErrorCollectorProvider({ children }) {
  const errorCollector = useErrorCollector()
  return <ErrorCollectorContext.Provider value={errorCollector}>{children}</ErrorCollectorContext.Provider>
}

export const useErrorCollectorContext = () => useContext(ErrorCollectorContext)
