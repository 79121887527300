import { createContext, useEffect } from 'react'
import useWorkspace from '../hooks/dataFetching/useWorkspace'
import { useAuth } from './AuthProvider'

export const WorkspaceContext = createContext()

const WorkspaceProvider = ({ children }) => {
  const workspace = useWorkspace()
  const auth = useAuth()
  const workspaceId = auth?.user?.defaultWorkspaceId
  useEffect(() => {
    if (!workspaceId) {
      return
    }
    workspace.getAllMembersData({ workspaceId, disableCache: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId])
  return <WorkspaceContext.Provider value={workspace}>{children}</WorkspaceContext.Provider>
}

export default WorkspaceProvider
