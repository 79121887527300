import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import Avatar from '../ui/avatar/Avatar'
import getInitials from '../../utils/getInitials'

// eslint-disable-next-line max-lines-per-function
export default function ProfileDropdownMenu({
  sidebarCollapsed,
  userName,
  size,
  userNavigation,
  useIndigoBackground,
  userAvatar,
  userEmail
}) {
  return (
    <>
      <Menu
        as="div"
        className={size === 'sm' ? 'ml-3 relative' : 'px-3 relative text-left'}
        data-testid="profile-dropdown"
      >
        <div>
          <Menu.Button
            data-testid="profile-btn"
            className={classNames(
              'text-sm focus:ring-1 focus:ring-indigo-700 focus:bg-indigo-100 flex items-center hover:bg-indigo-50',
              {
                // 'bg-indigo-50': useIndigoBackground,
                'max-w-xs flex items-center rounded-full': size === 'sm',
                'group w-full rounded-md px-[11px] py-2 text-left font-medium text-gray-700 focus:ring-offset-gray-700':
                  size !== 'sm'
              }
            )}
          >
            <span className="flex items-center justify-between w-full overflow-hidden text-ellipsis">
              <span className="flex items-center justify-between flex-shrink-0 w-full min-w-0 space-x-3">
                <Avatar
                  className="flex-shrink-0"
                  useIndigoBackground={useIndigoBackground}
                  initials={getInitials(userName)}
                  imageUrl={userAvatar}
                />
                {size !== 'sm' && (
                  <span
                    className={classNames(
                      'flex flex-col flex-1 min-w-0 transition-all',
                      sidebarCollapsed && 'opacity-0'
                    )}
                  >
                    <span className={classNames('text-sm font-semibold truncate text-background-500')}>{userName}</span>
                    <span
                      className={classNames('text-xs font-normal truncate text-background-500')}
                      data-testid="profile-user"
                    >
                      {userEmail}
                    </span>
                  </span>
                )}
              </span>
            </span>
          </Menu.Button>
        </div>
        {userNavigation && userNavigation.length > 0 && (
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              className={classNames(
                size === 'sm'
                  ? 'absolute right-0 origin-top-right mt-2 w-48'
                  : 'fixed w-[232px] z-10 mx-3 origin-top left-0 mt-1',
                'rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none'
              )}
            >
              <div className="py-1">
                {userNavigation.map((item) => (
                  <Menu.Item key={item.name}>
                    {({ active }) => (
                      <Link
                        to={item.href}
                        data-testid="profile-menu-btn"
                        className={classNames(
                          /* istanbul ignore next */
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        {item.name}
                      </Link>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        )}
      </Menu>
    </>
  )
}
