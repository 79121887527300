// istanbul ignore file
import { useState } from 'react'
import { API_METHODS, API_SERVICES } from '../../utils/constants'
import useFetchAPI from '../useFetchAPI'
import PayloadNormalizer from '../../utils/payloadNormalizer'

const useOpportunity = () => {
  const [getAllOpportunity, isLoadingAllOpportunity, errorGettingAllOpportunity, allOpportunity, allOpportunityUtils] =
    useFetchAPI(API_METHODS.GET_ALL, API_SERVICES.OPPORTUNITY)
  const [getOneOpportunity, isLoadingOneOpportunity, errorGettingOneOpportunity, oneOpportunity, oneOpportunityUtils] =
    useFetchAPI(API_METHODS.GET_ONE, API_SERVICES.OPPORTUNITY)
  const [
    createOpportunityAPI,
    isCreatingOpportunity,
    errorCreatingOpportunity,
    createOpportunityState,
    createOpportunityUtils
  ] = useFetchAPI(API_METHODS.CREATE, API_SERVICES.OPPORTUNITY)
  const [removeOpportunity, isRemovingOpportunity, errorRemovingOpportunity] = useFetchAPI(
    API_METHODS.DELETE,
    API_SERVICES.OPPORTUNITY
  )
  const [updateOpportunityAPI, isUpdatingOpportunity, errorUpdatingOpportunity] = useFetchAPI(
    API_METHODS.UPDATE,
    API_SERVICES.OPPORTUNITY
  )

  const payloadNormalizer = new PayloadNormalizer()

  const createOpportunity = (payload, ...restArgument) => {
    const payloadNormalized = payloadNormalizer.opportunity(payload)
    return createOpportunityAPI(payloadNormalized, ...restArgument)
  }

  const updateOpportunity = (id, payload, ...restArgument) => {
    const payloadNormalized = payloadNormalizer.opportunity(payload)
    return updateOpportunityAPI(id, payloadNormalized, ...restArgument)
  }

  const [temporaryOpportunity, setTemporaryOpportunity] = useState()

  return {
    getAll: {
      action: getAllOpportunity,
      isLoading: isLoadingAllOpportunity,
      error: errorGettingAllOpportunity,
      result: allOpportunity || [],
      utils: allOpportunityUtils
    },
    getOne: {
      action: getOneOpportunity,
      isLoading: isLoadingOneOpportunity,
      error: errorGettingOneOpportunity,
      result: oneOpportunity,
      utils: oneOpportunityUtils
    },
    create: {
      action: createOpportunity,
      isLoading: isCreatingOpportunity,
      error: errorCreatingOpportunity,
      result: createOpportunityState,
      utils: createOpportunityUtils
    },
    remove: {
      action: removeOpportunity,
      isLoading: isRemovingOpportunity,
      error: errorRemovingOpportunity
    },
    update: {
      action: updateOpportunity,
      isLoading: isUpdatingOpportunity,
      error: errorUpdatingOpportunity
    },
    tempOpportunity: temporaryOpportunity,
    setTempOpportunity: setTemporaryOpportunity
  }
}

export default useOpportunity
