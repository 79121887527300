import { useState, useEffect } from 'react'

function useTopBannerNotification() {
  const [message, setMessage] = useState('')
  const [type, setType] = useState('success')
  const [title, setTitle] = useState('')
  const [autoRemove, setAutoRemove] = useState(false)

  // istanbul ignore next
  const setAlert = (title = '', message = '', type = 'success', autoRemove = true, callback) => {
    setTitle(title)
    setMessage(message)
    setType(type)
    setAutoRemove(autoRemove)
    callback && typeof callback === 'function' && callback()
  }

  const resetAlert = () => {
    setTitle('')
    setMessage('')
    setAutoRemove(false)
    setType('success')
  }

  useEffect(() => {
    autoRemove && setTimeout(resetAlert, 5000)
  }, [autoRemove])

  return {
    title,
    message,
    type,
    autoRemove,
    setAlert,
    resetAlert
  }
}

export default useTopBannerNotification
