// istanbul ignore file
import { useState } from 'react'
import { API_METHODS, API_SERVICES } from '../../utils/constants'
import useFetchAPI from '../useFetchAPI'
import PayloadNormalizer from '../../utils/payloadNormalizer'

const useProblem = () => {
  const [getAllProblem, isLoadingAllProblem, errorGettingAllProblem, allProblem, allProblemUtils] = useFetchAPI(
    API_METHODS.GET_ALL,
    API_SERVICES.PROBLEM
  )
  const [getOneProblem, isLoadingOneProblem, errorGettingOneProblem, oneProblem, oneProblemUtils] = useFetchAPI(
    API_METHODS.GET_ONE,
    API_SERVICES.PROBLEM
  )
  const [createProblemAPI, isCreatingProblem, errorCreatingProblem, createProblemState, createProblemUtils] =
    useFetchAPI(API_METHODS.CREATE, API_SERVICES.PROBLEM)
  const [removeProblem, isRemovingProblem, errorRemovingProblem] = useFetchAPI(API_METHODS.DELETE, API_SERVICES.PROBLEM)
  const [updateProblemAPI, isUpdatingProblem, errorUpdatingProblem] = useFetchAPI(
    API_METHODS.UPDATE,
    API_SERVICES.PROBLEM
  )

  const [temporaryProblem, setTemporaryProblem] = useState()

  const payloadNormalizer = new PayloadNormalizer()

  const createProblem = (payload, ...restArgument) => {
    const payloadNormalized = payloadNormalizer.problem(payload)
    return createProblemAPI(payloadNormalized, ...restArgument)
  }

  const updateProblem = (id, payload, ...restArgument) => {
    const payloadNormalized = payloadNormalizer.problem(payload)
    return updateProblemAPI(id, payloadNormalized, ...restArgument)
  }

  return {
    getAll: {
      action: getAllProblem,
      isLoading: isLoadingAllProblem,
      error: errorGettingAllProblem,
      result: allProblem || [],
      utils: allProblemUtils
    },
    getOne: {
      action: getOneProblem,
      isLoading: isLoadingOneProblem,
      error: errorGettingOneProblem,
      result: oneProblem,
      utils: oneProblemUtils
    },
    create: {
      action: createProblem,
      isLoading: isCreatingProblem,
      error: errorCreatingProblem,
      result: createProblemState,
      utils: createProblemUtils
    },
    remove: {
      action: removeProblem,
      isLoading: isRemovingProblem,
      error: errorRemovingProblem
    },
    update: {
      action: updateProblem,
      isLoading: isUpdatingProblem,
      error: errorUpdatingProblem
    },
    tempProblem: temporaryProblem,
    setTempProblem: setTemporaryProblem
  }
}

export default useProblem
