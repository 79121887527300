import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import Button from '../button/Button'

import Modal from './Modal'

export default function ConfirmationModal({
  title,
  type,
  modalOpen,
  setModalOpen,
  handleConfirm,
  displayName,
  hideCloseButton = true,
  subtext,
  children,
  modalTitle,
  confirmButtonText,
  confirmButtonLoading
}) {
  return (
    <Modal open={modalOpen} onClose={() => setModalOpen(false)} hideCloseButton={hideCloseButton}>
      <Modal.Body>
        <div>
          <div className="flex items-center justify-center w-12 h-12 mx-auto bg-red-200 rounded-full">
            <ExclamationTriangleIcon className="w-6 h-6 text-red-500" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <span className="text-lg font-medium leading-6 text-gray-900">
              {modalTitle ? modalTitle : `Delete ${title}`}
            </span>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                Are you sure you want to {type === 'delete' && 'remove'} {displayName}?
                <br />
                {subtext ? subtext : 'This action cannot be undone.'}
              </p>
            </div>
            {children}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
        <Button
          data-testid="modal-cancel-button"
          datacolor="dark"
          variant="outline"
          className="w-full"
          onClick={() => setModalOpen(false)}
        >
          Cancel
        </Button>
        <Button
          data-testid="modal-delete-button"
          color="dark"
          className="w-full"
          onClick={handleConfirm}
          disabled={confirmButtonLoading}
        >
          {confirmButtonText ? confirmButtonText : 'Yes, delete it'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
