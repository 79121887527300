import { createContext, useContext } from 'react'
import useTopBannerNotificationProvider from '../hooks/useTopBannerNotification'

const topBannerNotificationContext = createContext()
topBannerNotificationContext.displayName = 'TopBannerNotificationContext'

export default function TopBannerNotificationProvider({ children }) {
  const notification = useTopBannerNotificationProvider()
  return <topBannerNotificationContext.Provider value={notification}>{children}</topBannerNotificationContext.Provider>
}

export const useTopBannerNotification = () => useContext(topBannerNotificationContext)
