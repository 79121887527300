import classNames from 'classnames'

export default function RadioButton({ id, checked, label, disabled, onChange }) {
  return (
    <div className="flex items-center">
      <input
        id={id}
        type="radio"
        checked={checked}
        className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-0 focus:ring-indigo-500 hover:cursor-pointer"
        disabled={disabled}
        data-testid="radio-button"
        onChange={onChange}
      />
      <label
        htmlFor={id}
        className={classNames('flex leading-none ml-3 text-sm font-normal hover:cursor-pointer', {
          'text-gray-300': disabled,
          'text-background-500': !disabled
        })}
      >
        {label}
      </label>
    </div>
  )
}
