import { useGate } from 'statsig-react'

const useFeatureFlag = (flag) => {
  const { value, isLoading } = useGate(flag)
  return {
    value,
    isLoading
  }
}

export default useFeatureFlag
