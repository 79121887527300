/* istanbul ignore file */

import { useState } from 'react'
import { useForm } from 'react-hook-form'
import Button from '../../ui/button/Button'
// import CopyLinkButton from '../CopyLinkButton'
import ControlledMultiSelectInput from '../../ui/form/ControlledMultiSelectInput'
import ErrorMesssage from '../../helpers/ErrorMessage'
import SuccessMessage from '../../helpers/SuccessMessage'

const CollaborateOnWorkspace = ({
  onFormSubmit,
  isFormLoading,
  onFormClose,
  hidePreviousStep,
  cancelButtonLabel = 'Do this later'
}) => {
  const fields = {
    defaultValue: {
      emails: [],
      message: ''
    },
    rules: {
      emails: { required: true },
      message: { required: false }
    }
  }

  const [errorMessage, setErrorMessage] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)

  const { handleSubmit, control, watch, reset } = useForm({
    defaultValues: fields.defaultValue
  })

  const watchEmails = watch('emails')
  const disableButton = !watchEmails || watchEmails.length === 0

  const onSubmit = async (data) => {
    try {
      setSuccessMessage(null)
      setErrorMessage(null)
      await onFormSubmit(data)
      reset()
      setSuccessMessage('Invitations sent successfully')
    } catch (error) {
      setErrorMessage(error.message)
    }
  }

  return (
    <>
      {errorMessage && <ErrorMesssage errorMessage={errorMessage} />}
      {successMessage && <SuccessMessage successMessage={successMessage} />}
      <ControlledMultiSelectInput
        id="emails"
        control={control}
        rules={fields.rules.emails}
        label="Invite people"
        placeholder="Type emails separated by a comma"
      />

      {/* <ControlledTextArea
        id="message"
        control={control}
        rules={fields.rules.message}
        label="Add your message"
        placeholder={`Hey! I would like you to be part of the ${auth.user.defaultOrgName} Workspace`}
        rows={4}
        inputClassName="text-gray-600 text-sm"
      /> */}

      {/* <CopyLinkButton /> */}

      <div className="!mt-[60px] flex justify-between items-center">
        <span className="text-sm text-gray-500">{!hidePreviousStep && 'Step 2 of 2'}</span>
        <div className="flex items-center space-x-4">
          {!isFormLoading && (
            <Button
              onClick={onFormClose}
              color="white"
              variant="outline"
              customSize="py-2 rounded-md px-8"
              className="flex items-center space-x-2 text-sm !text-gray-700"
            >
              {cancelButtonLabel}
            </Button>
          )}
          <Button
            onClick={handleSubmit(onSubmit)}
            color="coolGray"
            customSize="py-2 rounded-md px-4"
            className="flex items-center space-x-2 text-sm"
            disabled={disableButton}
          >
            <span>{isFormLoading ? 'Loading...' : 'Invite'}</span>
          </Button>
        </div>
      </div>
    </>
  )
}

export default CollaborateOnWorkspace
