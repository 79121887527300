import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'

import DropdownButton from '../form/DropdownButton'

export default function MenuButton({
  testid,
  options,
  buttonAction,
  bgColor = 'bg-transparent',
  classNameButton,
  preventDefault,
  menuIcon,
  classNameMenu,
  color,
  dropdownActiveColor,
  ringColor,
  shouldStopPropagation,
  minWidth,
  customClassMenuItems,
  disabled,
  customDisplay
}) {
  return (
    <DropdownButton
      testid={testid}
      className={classNames('p-0 !m-0 !shadow-none', {
        'border-none ': !classNameButton,
        [classNameMenu]: classNameMenu
      })}
      bgColor={bgColor}
      color={color}
      classNameButton={classNames('m-0 !shadow-none', {
        'border-none !p-0': !classNameButton,
        [classNameButton]: classNameButton
      })}
      alignRightItems
      downIconFalse
      options={options}
      staticTitle
      icon={menuIcon ? menuIcon : EllipsisVerticalIcon}
      buttonAction={buttonAction}
      preventDefault={preventDefault}
      dropdownActiveColor={dropdownActiveColor}
      ringColor={ringColor}
      shouldStopPropagation={shouldStopPropagation}
      minWidth={minWidth}
      customClassMenuItems={customClassMenuItems}
      disabled={disabled}
      customDisplay={customDisplay}
    />
  )
}
