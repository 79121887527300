import { createContext } from 'react'
import useJourney from '../hooks/dataFetching/useJourney'

export const JourneyContext = createContext()

const JourneyProvider = ({ children }) => {
  const journey = useJourney()
  return <JourneyContext.Provider value={journey}>{children}</JourneyContext.Provider>
}

export default JourneyProvider
