import React from 'react'
import ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'
import App from './app'
import reportWebVitals from './reportWebVitals'
import { logger } from './utils/logger'

// NOTE: Firebase perf monitoring only requires an import
// eslint-disable-next-line no-unused-vars
import { CONFIG } from './utils/constants'

import './styles.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

if (CONFIG.SENTRY_DNS) {
  Sentry.init({
    dsn: CONFIG.SENTRY_DNS,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        )
      })
    ],
    tracesSampleRate: CONFIG.IS_PRODUCTION ? 0.5 : 1,
    enabled: !CONFIG.IS_LOCAL,
    environment: CONFIG.ENV_NAME,
    release: CONFIG.SENTRY_RELEASE
  })
}

const root = ReactDOM.createRoot(document.querySelector('#root'))

root.render(<App />)

reportWebVitals(logger.info)
