/* eslint-disable indent */
import { TrashIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
import MenuButton from '../../components/ui/button/MenuButton'

export default function ImprovementMenuButton({
  handleSelect,
  bgColor,
  classNameButton,
  type,
  classNameMenu,
  color,
  dropdownActiveColor,
  ringColor,
  shouldStopPropagation
}) {
  const menuOptions = [
    ...(['sidebarNavigation'].includes(type)
      ? [
          {
            name: 'Rename',
            value: 'rename-board',
            icon: <PencilSquareIcon className="inline w-5 h-5 mr-1" />
          }
        ]
      : []),
    ...(['sidebarNavigation'].includes(type)
      ? [
          {
            name: 'Remove View',
            value: 'remove-board',
            icon: <TrashIcon className="inline w-5 h-5 mr-1" />,
            hasBorder: true
          }
        ]
      : [])
  ]

  return (
    <MenuButton
      testid="improvement-sidebar-menu-button"
      classNameButton={classNameButton}
      options={menuOptions}
      bgColor={bgColor}
      buttonAction={(value) => handleSelect(value)}
      classNameMenu={classNameMenu}
      color={color}
      dropdownActiveColor={dropdownActiveColor}
      ringColor={ringColor}
      shouldStopPropagation={shouldStopPropagation}
    />
  )
}
