import React from 'react'
import { Link } from 'react-router-dom'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import ImprovementMenuButton from '../../pages/improvements/ImprovementMenuButton'

// eslint-disable-next-line max-lines-per-function
export default function SidebarLink({
  link,
  readOnly,
  isActive,
  sidebarCollapsed,
  onClick,
  editableId,
  inputRefs,
  handleSaveName,
  handleMenuSelect,
  className
}) {
  if (!link) return null

  const linkState = {
    id: link.id,
    title: link.name,
    filter: link?.filter ?? /* istanbul ignore next */ ''
  }

  return (
    <Link
      key={link.href}
      to={link.href}
      onClick={onClick}
      className={classNames(
        'group flex items-center px-2 py-2 lg:text-sm text-base leading-5 font-medium rounded-md ml-4 whitespace-nowrap',
        `sidebar-item-${link?.name}`,
        { hidden: sidebarCollapsed },
        {
          'bg-indigo-100 text-indigo-600': isActive,
          'text-gray-500 hover:bg-indigo-50': !isActive
        },
        className
      )}
      state={linkState}
      aria-current={link.current ? 'page' : undefined}
      data-testid="navigation-link"
      data-tooltip-content={link?.name}
    >
      {/* Sidebar item icon */}
      {isActive ? (
        <link.icon
          data-testid="sidebar-link-solid-icon"
          className={classNames('text-indigo-500 mr-3 flex-shrink-0 h-6 w-6 transition-all', {
            'ml-2': sidebarCollapsed,
            'ml-0': !sidebarCollapsed
          })}
          aria-hidden="true"
        />
      ) : (
        <link.outlineIcon
          data-testid="sidebar-link-outline-icon"
          className={classNames('text-gray-800 mr-3 flex-shrink-0 h-6 w-6 transition-all', {
            'ml-2': sidebarCollapsed,
            'ml-0': !sidebarCollapsed
          })}
          aria-hidden="true"
        />
      )}

      {link?.menuButton ? (
        <input
          id="name"
          name="name"
          data-testid="name"
          defaultValue={link?.name}
          className={classNames(
            'block w-full p-0 h-6 text-sm leading-7 group-hover:cursor-pointer border-transparent caret-indigo-600 focus:outline-none focus:ring-0 focus:border-transparent',
            {
              'bg-indigo-100 text-indigo-500': isActive,
              'text-gray-800 bg-gray-50 group-hover:bg-indigo-50': !isActive,
              '!bg-white !border-[1px] !border-indigo-500 !rounded': editableId === link.id
            }
          )}
          type="text"
          readOnly={editableId !== link.id}
          ref={(element) => (inputRefs.current[link.id] = element)}
          onBlur={(event) => handleSaveName(event, link)}
        />
      ) : (
        <span
          className={classNames('transition-opacity', {
            'opacity-0': sidebarCollapsed,
            'opacity-100': !sidebarCollapsed
          })}
        >
          {link.name}
        </span>
      )}

      {link?.menuButton && !readOnly && (
        <div
          className={classNames('ml-auto', {
            'opacity-100': isActive,
            'opacity-0 group-hover:opacity-100': !isActive
          })}
        >
          <ImprovementMenuButton
            type="sidebarNavigation"
            handleSelect={(value) => handleMenuSelect(value, link.id)}
            bgColor={classNames({
              'bg-indigo-100 hover:bg-indigo-200 hover:text-gray-500': isActive,
              'bg-indigo-50 hover:bg-indigo-200': !isActive
            })}
            dropdownActiveColor={classNames('bg-indigo-500', {
              'text-white': isActive,
              'text-gray-500': !isActive
            })}
            classNameButton={classNames('p-[2px] !flex align-center focus:ring-offset-0', {
              'focus:ring-indigo-100': isActive,
              'focus:ring-indigo-50': !isActive
            })}
            ringColor={classNames('focus:ring-offset-0', {
              'focus:ring-indigo-100': isActive,
              'focus:ring-indigo-50': !isActive
            })}
            classNameMenu="!flex"
            color="text-gray-500 focus:text-gray-500"
            shouldStopPropagation={false}
          />
        </div>
      )}

      {/* Sidebar item chevron (if child is available) */}
      {link.children && (
        <span className="ml-auto">
          <ChevronDownIcon
            data-testid="chevron-icon"
            className={classNames('w-5 h-5 transition-transform', {
              'rotate-180': isActive
            })}
          />
        </span>
      )}
    </Link>
  )
}
