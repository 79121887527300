import { useState } from 'react'
import { logger } from '../utils/logger'

function useDashboardSidebarLayout({ sendVerificationEmail }) {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false)
  const [hideOnFullScreen, setHideOnFullScreen] = useState(true)
  const [workspacePanelOpen, setWorkspacePanelOpen] = useState(false)
  const [hideHeader, setHideHeader] = useState(true)
  const [sendingEmail, setSendingEmail] = useState(false)
  const [title, setTitle] = useState('')
  const [actionButton, setActionButton] = useState(null)
  const [showMapNavigation, setShowMapNavigation] = useState(false)

  const handleResendEmail = async () => {
    setSendingEmail(true)
    sendVerificationEmail()
      .then(() => {
        logger.info('email sent')
      })
      .catch((error) => {
        logger.info({ error })
      })
      .finally(() => {
        setSendingEmail(false)
      })
  }

  return {
    sidebarOpen,
    sidebarCollapsed,
    hideOnFullScreen,
    workspacePanelOpen,
    hideHeader,
    sendingEmail,
    title,
    actionBtn: actionButton,
    setSidebarOpen,
    setSidebarCollapsed,
    setHideOnFullScreen,
    setWorkspacePanelOpen,
    setHideHeader,
    setSendingEmail,
    setTitle,
    setActionBtn: setActionButton,
    handleResendEmail,
    showMapNavigation,
    setShowMapNavigation
  }
}
export default useDashboardSidebarLayout
