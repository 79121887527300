// estlint disable
import { useRef } from 'react'

const ONE_SECOND = 1000

const useErrorCollector = () => {
  const errorLogReference = useRef([])

  const MAX_ERROR_IN_A_SECOND = 3

  const collectErrors = async (_error, { service }) => {
    const now = Date.now() // Current timestamp in milliseconds

    // Add the new error to the log
    errorLogReference.current = [...errorLogReference.current, { service, timestamp: now }]

    // Remove errors older than 1 second
    errorLogReference.current = errorLogReference.current.filter((error) => now - error.timestamp <= ONE_SECOND)

    // Get unique services that have errors within the last second
    const uniqueServicesWithErrors = new Set(errorLogReference.current.map((error) => error.service))

    // If there are more than 3 unique services with errors, refresh the page
    if (uniqueServicesWithErrors.size > MAX_ERROR_IN_A_SECOND) {
      window.location.reload()
      errorLogReference.current = []
    }
  }

  return { collectErrors, errorLogRef: errorLogReference }
}

export default useErrorCollector
