// Use a custom history object and pass to Router so that we
// can utilize history.listen() where needed (such as for pageview tracking)
import { unstable_HistoryRouter as RouterOriginal } from 'react-router-dom'
import { createBrowserHistory } from 'history'

export const history = createBrowserHistory()

export default function Router({ children }) {
  return <RouterOriginal history={history}>{children}</RouterOriginal>
}
