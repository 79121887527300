import { useController } from 'react-hook-form'
import MultiSelectInput from './MultiSelectInput'

const ControlledMultiSelectInput = ({ id, control, rules, ...properties }) => {
  const { field } = useController({
    name: id,
    control,
    rules
  })

  return <MultiSelectInput value={field.value} onChange={field.onChange} {...properties} />
}

export default ControlledMultiSelectInput
