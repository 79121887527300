const getInitials = (fullName) => {
  if (fullName) {
    const names = fullName.split(' ')
    let initials = names[0].slice(0, 1).toUpperCase()

    if (names.length > 1) {
      initials += names.at(-1).slice(0, 1).toUpperCase()
    }
    return initials
  }
  return fullName
}

export default getInitials
