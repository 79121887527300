import classNames from 'classnames'
import { Bars3Icon } from '@heroicons/react/24/solid'
import NotificationBell from '../notification-bell/NotificationBell'
import MapNavigation from '../../mapNavigation/MapNavigation'

export default function AppHeader({
  title,
  ActionButtonComponent,
  hideOnFullScreen,
  hideHeader,
  showMapNavigation,
  setSidebarOpen
}) {
  return (
    <div
      data-testid="app-header"
      className={classNames(
        {
          hidden: hideOnFullScreen || hideHeader,
          'sm:flex sm:items-center sm:justify-between': !hideOnFullScreen && !hideHeader
        },
        'sticky top-0 px-4 py-4 bg-white sm:px-6 lg:px-8 z-10'
      )}
    >
      <div className="flex flex-row items-center justify-between w-full">
        <button
          data-testid="sidebar-opener-mobile"
          type="button"
          className="pr-6 text-gray-500 focus:outline-none focus:ring-0 focus:ring-inset focus:ring-purple-500 lg:hidden"
          onClick={() => setSidebarOpen(true)}
        >
          <Bars3Icon className="w-8 h-8 text-gray-800" aria-hidden="true" />
        </button>
        <h1 className="flex-1 text-lg font-semibold leading-6 text-gray-900 sm:truncate">{title}</h1>
        {showMapNavigation && <MapNavigation />}
        <div className="flex items-center justify-end flex-1 gap-2">
          {ActionButtonComponent && <ActionButtonComponent />}
          <NotificationBell />
        </div>
      </div>
    </div>
  )
}
