import { useEffect } from 'react'

export default function useOnClickOutside(references, handler) {
  // Ensure refs is an array
  if (!Array.isArray(references)) {
    references = [references]
  }

  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking any ref's element or descendent elements
        for (const reference of references) {
          // istanbul ignore next
          if (reference.current && reference.current.contains(event.target)) {
            // istanbul ignore next
            return
          }
        }

        handler(event)
      }

      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)

      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    },
    // Add refs and handler to effect dependencies
    // If your handler function changes often, consider using useCallback
    // to prevent the effect running unnecessarily.
    [references, handler]
  )
}
