import { MapIcon, QueueListIcon, ViewColumnsIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'

const MapNavigation = ({ name }) => {
  const navigate = useNavigate()
  const { journeyId: mapId } = useParams()
  const location = useLocation()
  const [searchParameters] = useSearchParams()
  const mapName = name || searchParameters.get('name')
  const { pathname } = location

  return (
    <div
      className="shadow-md bg-gray-100 rounded-lg border border-gray-200 border-solid h-[44px] flex items-center justify-center p-1 text-indigo-500 gap-1"
      data-testid="map-navigation"
    >
      <button
        className={classNames(
          'map-menu px-2 h-full rounded-md hover:bg-white text-sm text-gray-800 flex items-center hover:text-indigo-500',
          {
            'text-indigo-500 bg-white': pathname.includes('edit')
          }
        )}
        data-testid="map-button"
        onClick={() => navigate(`/maps/${mapId}/edit`)}
      >
        <MapIcon className="w-5 h-5 mr-2" /> <span>Map</span>
      </button>
      <button
        className={classNames(
          'map-menu px-2 h-full rounded-md hover:bg-white text-sm text-gray-800 flex items-center hover:text-indigo-500',
          {
            'text-indigo-500 bg-white': pathname.includes('tasks')
          }
        )}
        data-testid="tasks-button"
        onClick={() => navigate(`/maps/${mapId}/tasks?name=${mapName}`)}
      >
        <QueueListIcon className="w-5 h-5 mr-2" /> <span>List</span>
      </button>
      <button
        className={classNames(
          'map-menu px-2 h-full rounded-md hover:bg-white text-sm text-gray-800 flex items-center hover:text-indigo-500',
          {
            'text-indigo-500 bg-white': pathname.includes('board')
          }
        )}
        data-testid="board-button"
        onClick={() => navigate(`/maps/${mapId}/board?name=${mapName}`)}
      >
        <ViewColumnsIcon className="w-5 h-5 mr-2" /> <span>Board</span>
      </button>
    </div>
  )
}

export default MapNavigation
