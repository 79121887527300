import React from 'react'

import classNames from 'classnames'
import LogoGlyph from '../logo/LogoGlyph'

export default function OnboardingHeader({ hideLogo = false, title, workspaceName, description }) {
  return (
    <div className={classNames('flex flex-col items-center justify-center mb-8 space-y-6')}>
      {!hideLogo && <LogoGlyph className="h-[60px]" />}
      <div>
        {title?.map((t, key) => {
          return (
            <h2 key={key} className="text-xl font-bold text-center text-gray-900">
              {t}
            </h2>
          )
        })}
      </div>
      {workspaceName && (
        <p data-testid="workspace-name" className="my-4 text-sm font-semibold text-center text-gray-500">
          {workspaceName}
        </p>
      )}
      <p className="text-sm text-center text-gray-500">{description}</p>
    </div>
  )
}
