import { createContext } from 'react'
import useProblem from '../hooks/dataFetching/useProblem'

export const ProblemContext = createContext()

const ProblemProvider = ({ children }) => {
  const problem = useProblem()
  return <ProblemContext.Provider value={problem}>{children}</ProblemContext.Provider>
}

export default ProblemProvider
