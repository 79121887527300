import { useAuth0 } from '@auth0/auth0-react'

const useGetToken = (options) => {
  const { getAccessTokenSilently } = useAuth0()
  return async () => {
    try {
      const token = await getAccessTokenSilently({
        ...options,
        detailedResponse: true
      })
      // const expiresIn = token.expires_in
      return token.access_token
    } catch {
      return null
    }
  }
}

export default useGetToken
