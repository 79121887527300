import { createContext } from 'react'
import useIssue from '../hooks/dataFetching/useIssue'

export const IssueContext = createContext()

const IssueProvider = ({ children }) => {
  const issue = useIssue()
  return <IssueContext.Provider value={issue}>{children}</IssueContext.Provider>
}

export default IssueProvider
