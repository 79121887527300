import { useContext, createContext } from 'react'
import useAlert from '../hooks/useAlert'
import Alert from '../components/alert/Alert'

const AlertContext = createContext()

// This should wrap the app in `src/components/app.js`
export default function AlertProvider({ children }) {
  const alert = useAlert()
  const open = !!alert.title && !!alert.message
  return (
    <AlertContext.Provider value={alert}>
      {children}
      {open && (
        <div className="fixed z-50 top-0 left-0 right-0 mr-auto ml-auto max-w-lg w-full flex justify-center">
          <div className="py-16 px-7 w-full">
            <Alert type={alert?.type} title={alert?.title} message={alert?.message} onClose={alert?.closeAlert} />
          </div>
        </div>
      )}
    </AlertContext.Provider>
  )
}

export const useAlertContext = () => useContext(AlertContext)
