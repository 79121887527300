import React, { useRef, useState } from 'react'
import { Float } from '@headlessui-float/react'
import { Combobox } from '@headlessui/react'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'

import Button from '../button/Button'

export default function ComboBoxButton({
  options,
  selected,
  placeholder,
  children,
  color,
  customColor,
  className,
  optionClassName,
  searchable = true,
  customOption,
  onChange,
  placement = 'bottom-start',
  portal = true,
  disabled
}) {
  const containerReference = useRef()
  const [query, setQuery] = useState('')

  const filterOptions = options.filter((option) =>
    option?.name?.toLowerCase().replaceAll(/\s+/g, '').includes(query.toLowerCase().replaceAll(/\s+/g, ''))
  )

  const filteredOptions = query === '' ? options : filterOptions

  return (
    <Combobox value={selected} by="id" onChange={onChange} disabled={disabled}>
      <div className="relative inline-block" ref={containerReference}>
        <Float
          adaptiveWidth={true}
          placement={placement}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
          portal={portal}
        >
          <div>
            <Combobox.Button
              as={React.forwardRef((properties, reference) => (
                <Button innerRef={reference} {...properties} />
              ))}
              color={color}
              customColor={customColor}
              className={className}
            >
              {children}
            </Combobox.Button>
          </div>

          <Combobox.Options
            className={classNames(
              'absolute z-[11] min-w-[180px] p-4 w-full overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm list-none',
              { [optionClassName]: optionClassName }
            )}
            static
          >
            {searchable && (
              <div className="relative">
                <MagnifyingGlassIcon className="absolute w-5 h-5 text-gray-400 top-2 left-2" />
                <Combobox.Input
                  className="w-full py-2 pl-8 text-sm font-normal text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-0 focus:border-gray-300"
                  onChange={(event) => setQuery(event.target.value)}
                  data-testid="search-input"
                  placeholder={placeholder}
                />
              </div>
            )}
            {filteredOptions.length === 0 && query !== '' ? (
              <div
                className="relative px-4 py-2 text-gray-700 cursor-default select-none"
                data-testid="notfound-options"
              >
                Nothing found.
              </div>
            ) : (
              filteredOptions.map((option) => (
                <Combobox.Option
                  key={option.id}
                  className={({ active }) =>
                    classNames('relative cursor-pointer text-gray-900 select-none py-2 px-4', {
                      'bg-gray-100': active,
                      'border-t': option.separator,
                      'border-gray-300': option.separator,
                      'border-solid': option.separator
                    })
                  }
                  value={option}
                  data-testid="combobox-options"
                >
                  {customOption ? (
                    customOption(option)
                  ) : (
                    <span
                      className={classNames('flex items-center truncate', {
                        'font-medium': selected,
                        'font-normal': !selected
                      })}
                    >
                      {option.icon && <option.icon className="inline w-5 h-5 mr-2 text-gray-500" />}
                      {option.name}
                    </span>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Float>
      </div>
    </Combobox>
  )
}
