/* eslint-disable indent */
import { Cog6ToothIcon, RectangleGroupIcon, UserPlusIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
import MenuButton from '../../components/ui/button/MenuButton'

export default function WorkspaceMenuButton({
  handleSelect,
  bgColor,
  classNameButton,
  type,
  classNameMenu,
  color,
  dropdownActiveColor,
  ringColor,
  shouldStopPropagation,
  minWidth,
  customClassMenuItems,
  permissions,
  icon
}) {
  const isAdmin = !!permissions?.workspaces?.manage

  const menuOptions = [
    ...(['other'].includes(type) && isAdmin
      ? [
          {
            name: 'Invite new user',
            value: 'invite-user',
            icon: <UserPlusIcon className="inline w-5 h-5 mr-3" />
          }
        ]
      : []),
    ...(['sidebar', 'other'].includes(type) && isAdmin
      ? [
          {
            name: 'Create workspace',
            value: 'create-workspace',
            icon: <RectangleGroupIcon className="inline w-5 h-5 mr-3" />
          }
        ]
      : []),
    ...(['switchable-workspace'].includes(type)
      ? [
          {
            name: 'Switch workspace',
            value: 'switch-workspace',
            icon: <RectangleGroupIcon className="inline w-5 h-5 mr-3" />
          }
        ]
      : []),
    ...(['other'].includes(type) && isAdmin
      ? [
          {
            name: 'Settings',
            value: 'settings',
            icon: <Cog6ToothIcon className="inline w-5 h-5 mr-3" />
          }
        ]
      : []),
    ...(['sidebar', 'switchable-workspace'].includes(type) && isAdmin
      ? [
          {
            name: 'Manage Members',
            value: 'manage-members',
            icon: <UserPlusIcon className="inline w-5 h-5 mr-3" />
          }
        ]
      : []),
    ...(['switchable-workspace'].includes(type) && isAdmin
      ? [
          {
            name: 'Rename Workspace',
            value: 'rename-workspace',
            icon: <PencilSquareIcon className="inline w-5 h-5 mr-3" />
          }
        ]
      : [])
  ]

  return (
    <MenuButton
      testid="workspace-sidebar-menu-button"
      classNameButton={classNameButton}
      options={menuOptions}
      bgColor={bgColor}
      buttonAction={handleSelect}
      classNameMenu={classNameMenu}
      color={color}
      menuIcon={icon}
      dropdownActiveColor={dropdownActiveColor}
      ringColor={ringColor}
      shouldStopPropagation={shouldStopPropagation}
      minWidth={minWidth}
      customClassMenuItems={customClassMenuItems}
    />
  )
}
