import { useState } from 'react'
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'

export default function TextField({
  id,
  type,
  label,
  labelSize,
  error,
  helperText,
  icon: Icon,
  placeholder,
  maxLength,
  inputClassName,
  showRequiredStar,
  labelLogo,
  value,
  onChange,
  ...rest
}) {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <div>
      <div className={classNames({ 'flex space-x-4': labelLogo })}>
        {label && (
          <label
            htmlFor={id}
            className={classNames('block', {
              'text-red-600': error,
              'text-gray-700': !error,
              'text-sm font-medium': !labelSize,
              [labelSize]: labelSize
            })}
          >
            {showRequiredStar && '* '}
            {label}
          </label>
        )}
        {labelLogo && labelLogo}
      </div>
      <div className={classNames('mt-1', { 'flex rounded-md shadow-sm': type === 'url', relative: type !== 'url' })}>
        {Icon && (
          <Icon
            className="absolute w-5 h-5 top-[50%] left-2 translate-y-[-50%] text-gray-400"
            data-testid="textfield-icon"
          />
        )}
        {type === 'url' && (
          <span className="inline-flex items-center px-3 text-gray-500 border border-r-0 border-gray-300 bg-gray-50 rounded-l-md sm:text-sm">
            http://
          </span>
        )}
        <input
          id={id}
          type={type === 'password' && showPassword ? 'text' : type}
          value={value}
          onChange={onChange}
          className={classNames(
            'py-2 block w-full sm:text-sm rounded-md focus:outline-none shadow-sm appearance-none',
            {
              'pr-10 text-red-900 placeholder-red-300 border border-red-300 focus:ring-0 focus:ring-red-500': error,
              'placeholder-gray-400 border border-gray-300 focus:ring-0 focus:ring-indigo-500': !error,
              'pl-8 pr-3': Icon,
              'px-3': !Icon,
              [inputClassName]: inputClassName,
              'rounded-l-none': type === 'url',
              'rounded-l-md': type !== 'url'
            }
          )}
          aria-invalid={error ? 'true' : 'false'}
          aria-describedby={`${id}-error`}
          data-testid={`textfield-${id}`}
          placeholder={placeholder}
          maxLength={maxLength}
          {...rest}
        />
        {type === 'password' && (
          <button
            className="absolute inset-y-0 right-0 flex items-center pr-3 text-sm cursor-pointer"
            type="button"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? (
              <EyeSlashIcon className="w-6 h-6 text-gray-500" />
            ) : (
              <EyeIcon className="w-6 h-6 text-gray-500" />
            )}
          </button>
        )}
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
          {error.message}
        </p>
      )}
      {helperText && (
        <p className="mt-2 text-sm text-gray-500" id={`${id}-helper`}>
          {helperText}
        </p>
      )}
    </div>
  )
}
