import { useState } from 'react'
import { API_SERVICES, CONFIG } from '../utils/constants'
import sanitizeObject from '../utils/sanitizeObject'
import useGetToken from './useGetToken'

const useChangeRole = () => {
  const getToken = useGetToken()
  const [isLoading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const changeUserRoleInWorkspace = async ({ workspaceId, userId, role }) => {
    try {
      const token = await getToken(true)
      if (!token) {
        throw new Error("Can't get token from auth0")
      }

      if (!workspaceId) {
        throw new Error('Workspace id is required')
      }

      if (!userId) {
        throw new Error('User id is required')
      }

      if (!role) {
        throw new Error('Role is required')
      }

      const data = {
        userId,
        role
      }
      setErrorMessage(null)
      setLoading(true)
      const sanitizedPayload = sanitizeObject(data)
      const response = await fetch(
        `${CONFIG.BACKEND_BASE_URL}/${API_SERVICES.WORKSPACE}/${workspaceId}/role?disableCache=true`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify(sanitizedPayload)
        }
      )

      const result = await response.json()

      if (result.error) {
        throw new Error(result.message)
      }
      if (!response.ok) {
        throw new Error(result.message)
      }
      return result
    } catch (error) {
      setErrorMessage(error.message)
    } finally {
      setLoading(false)
    }
  }

  const resetErrorMessage = () => {
    setErrorMessage(null)
  }

  return {
    changeUserRoleInWorkspace,
    isLoading,
    errorMessage,
    resetErrorMessage
  }
}

export default useChangeRole
