import classNames from '../../../utils/text/classnames'

export default function AvatarBackgroundImage({ size = 'sm', imageUrl, className, ...rest }) {
  return (
    <div
      data-testid="avatar-bg-image"
      style={{ backgroundImage: `url(${imageUrl})`, paddingBottom: '100%' }}
      className={classNames('bg-cover', 'bg-center', 'rounded-full', 'bg-gray-500')}
    />
  )
}
