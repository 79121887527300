function sanitizeObject(object) {
  let newObject = {}

  for (let key in object) {
    if (['_id', 'auditLog', 'triggerFunctionExecutionTime'].includes(key)) {
      continue
    }
    let value = object[key]
    let valueType = typeof value

    // Check if value is not empty depending on its type
    if (
      (valueType === 'string' && value.trim() !== '') ||
      valueType === 'number' || // This will include 0
      valueType === 'boolean' ||
      (valueType === 'object' && value !== null && Object.keys(value).length > 0)
    ) {
      newObject[key] = value
    } else if (Array.isArray(value)) {
      newObject[key] = value
    }
  }
  return newObject
}

export default sanitizeObject
