import classNames from 'classnames'
import TrinitiLogoGlyphPurple from '../../assets/images/triniti-logo-purple.png'

export default function LogoGlyph({ className }) {
  return (
    <img
      className={classNames('brightness-100 h-7 w-auto', { [className]: className })}
      src={TrinitiLogoGlyphPurple}
      alt="Triniti Logo"
    />
  )
}
