import { useEffect, useRef } from 'react'
import classNames from '../../../utils/text/classnames'

export default function Badge({
  color,
  size,
  rounded,
  children,
  customColor,
  customSize,
  className,
  getBadgeWidth,
  enableHover,
  ...properties
}) {
  const badgeReference = useRef(0)

  const colors = {
    primary: 'bg-indigo-100 text-indigo-600',
    dark: 'bg-dark-navy-500 text-white',
    gray: 'bg-pale-ash-300 text-soft-navy-500',
    red: 'bg-red-100 text-red-800',
    yellow: 'bg-yellow-100 text-yellow-800',
    green: 'bg-green-100 text-green-800',
    blue: 'bg-blue-100 text-blue-800',
    indigo: 'bg-indigo-100 text-indigo-800',
    purple: 'bg-purple-100 text-purple-800',
    pink: 'bg-pink-100 text-pink-800',
    lightGray: 'bg-gray-100 text-gray-800',
    white: 'bg-white text-gray-900'
  }

  const hoverColors = {
    primary: 'hover:bg-indigo-200',
    dark: 'hover:bg-dark-navy-600',
    gray: 'hover:bg-pale-ash-400',
    red: 'hover:bg-red-200',
    yellow: 'hover:bg-yellow-300',
    green: 'hover:bg-green-300',
    blue: 'hover:bg-blue-200',
    indigo: 'hover:bg-indigo-200',
    purple: 'hover:bg-purple-200',
    pink: 'hover:bg-pink-200',
    lightGray: 'hover:bg-gray-200'
  }

  const sizes = {
    md: 'px-2 py-0.5 text-xs',
    lg: 'px-2.5 py-0.5 text-sm',
    xl: 'w-10 h-10 justify-center',
    block: 'py-0.5 px-2 w-full justify-center text-xs'
  }

  const roundedBorders = {
    md: 'rounded-md',
    full: 'rounded-full'
  }

  const actualColor = customColor ? customColor : color ? colors[color] : colors.primary
  const actualSize = customSize ? customSize : size ? sizes[size] : sizes.md
  const actualRoundedBorder = rounded ? roundedBorders[rounded] : roundedBorders.full

  useEffect(() => {
    if (getBadgeWidth && badgeReference.current) {
      getBadgeWidth(badgeReference.current?.clientWidth)
    }
  }, [getBadgeWidth])

  return (
    <span
      ref={badgeReference}
      className={classNames(
        'badge inline-flex items-center font-medium',
        actualColor,
        actualSize,
        actualRoundedBorder,
        enableHover && hoverColors[color],
        className
      )}
      data-testid="badge"
      {...properties}
    >
      {children}
    </span>
  )
}
