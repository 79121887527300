import { createContext } from 'react'
import useComment from '../hooks/dataFetching/useComment'

export const CommentContext = createContext()

const CommentProvider = ({ children }) => {
  const comment = useComment()
  return <CommentContext.Provider value={comment}>{children}</CommentContext.Provider>
}

export default CommentProvider
