import { useSearchParams } from 'react-router-dom'

const useFreshAccount = () => {
  const [searchParameters] = useSearchParams()
  const success = searchParameters.get('success')
  const message = searchParameters.get('message')
  return {
    success: success === 'true',
    message
  }
}

export default useFreshAccount
